import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  StatusBar,
  useWindowDimensions,
  useColorScheme as useDeviceColorScheme,
  Modal,
  Switch,
} from "react-native";
import moment from "moment-timezone";
import axios from "axios";
import { Feather, MaterialIcons } from "@expo/vector-icons";
import { useLocalSearchParams } from "expo-router";
import { useNavigation } from "@react-navigation/native";
import CustomAlert from "./CustomAlert";
import TimeInput from "./TimeInput";
import SignatureCanvas from "react-signature-canvas";
import CryptoJS from "crypto-js";
import SpecialFormatTimesheet from "./SpecialFormatTimesheet";
import CustomLoading from "./CustomLoading";

const calculateTotalTime = (startTime, endTime) => {
  if (!startTime || !endTime) return 0;

  const format = "h:mm A";
  const start = moment.tz(startTime, format, "America/Phoenix");
  let end = moment.tz(endTime, format, "America/Phoenix");

  if (end.isBefore(start)) {
    end.add(1, "day");
  }

  const duration = moment.duration(end.diff(start));
  return parseFloat(duration.asHours().toFixed(2));
};

const calculateDailyTotal = (day) => {
  if (!day || !Array.isArray(day.services)) {
    return { regularHours: 0, standbyHours: 0, total: 0 };
  }

  const serviceTotal = day.services.reduce(
    (sum, service) => sum + (service.totalTime || 0),
    0
  );

  const lunchDuration =
    day.lunchStartTime && day.lunchEndTime
      ? calculateTotalTime(day.lunchStartTime, day.lunchEndTime)
      : 0;

  if (day.isStandBy) {
    const standbyHours = Math.max(24 - serviceTotal, 0);
    return {
      regularHours: serviceTotal,
      standbyHours,
      total: serviceTotal + standbyHours,
    };
  } else {
    const regularHours = Math.max(serviceTotal - lunchDuration, 0);
    return { regularHours, standbyHours: 0, total: regularHours };
  }
};

const getCurrentWeekDates = () => {
  const now = moment().tz("America/Phoenix");
  const currentWeekStart = now.clone().startOf("week");
  const lastWeekStart = currentWeekStart.clone().subtract(1, "week");
  const submissionDeadline = currentWeekStart
    .clone()
    .add(1, "days")
    .hour(12)
    .minute(0)
    .second(0);

  let weekStart;
  let isSubmissionOpen;

  if (now.isBefore(submissionDeadline)) {
    weekStart = lastWeekStart;
    isSubmissionOpen = true;
  } else {
    weekStart = currentWeekStart;
    isSubmissionOpen = true;
  }

  return { weekStart, isSubmissionOpen };
};

const getWeekDays = (weekStart) => {
  return Array.from({ length: 7 }, (_, i) => weekStart.clone().add(i, "days"));
};

const getTimesheetMessage = (weekStart) => {
  const now = moment().tz("America/Phoenix");
  const currentWeekStart = now.clone().startOf("week");
  const submissionDeadline = currentWeekStart
    .clone()
    .add(1, "days")
    .hour(12)
    .minute(0)
    .second(0);

  if (weekStart.isSame(currentWeekStart, "day")) {
    return "You are viewing the current week's timesheet.";
  } else {
    if (now.isBefore(submissionDeadline)) {
      return `You are viewing last week's timesheet. Submission deadline is ${submissionDeadline.format(
        "dddd, MMMM D"
      )} at 12:00 PM.`;
    } else {
      return "You are viewing last week's timesheet. The submission deadline has passed.";
    }
  }
};

const BackButton = ({ color }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color={color} />
    </TouchableOpacity>
  );
};

const User = () => {
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;
  const navigation = useNavigation();
  const params = useLocalSearchParams();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const [isLoading, setIsLoading] = useState(true);

  const [recentHistory, setRecentHistory] = useState([]);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [startTimes, setStartTimes] = useState(Array(7).fill(""));
  const [endTimes, setEndTimes] = useState(Array(7).fill(""));
  const [lunchStartTimes, setLunchStartTimes] = useState(Array(7).fill(""));
  const [lunchEndTimes, setLunchEndTimes] = useState(Array(7).fill(""));
  const [totalWorkHours, setTotalWorkHours] = useState(Array(7).fill(0));
  const [weeklyTotalHours, setWeeklyTotalHours] = useState(0);
  const [vacationTimes, setVacationTimes] = useState(Array(7).fill(""));
  const [sickTimes, setSickTimes] = useState(Array(7).fill(""));
  const [holidayTimes, setHolidayTimes] = useState(Array(7).fill(""));
  const [canSubmit, setCanSubmit] = useState(true);
  const [savedDays, setSavedDays] = useState(Array(7).fill(false));
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [showCancel, setShowCancel] = useState(true);

  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);

  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const scrollViewRef = useRef(null);

  const [timesheetData, setTimesheetData] = useState([]);
  const [weeklyRegularHours, setWeeklyRegularHours] = useState(0);
  const [weeklyStandbyHours, setWeeklyStandbyHours] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const signaturePadRef = useRef(null);

  const isSpecialFormatEmployee =
    params?.id === "919867" ||
    params?.id === "819629" ||
    params?.id === "460392" ||
    params?.id === "845348" ||
    params?.id === "891026" ||
    params?.id === "491346" ||
    params?.id === "150991";

  const [specialFormatWeeklyTotal, setSpecialFormatWeeklyTotal] = useState(0);

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  const showAlert = useCallback(
    (message, title, onConfirm = null, showCancel = true) => {
      setAlertTitle(title);
      setAlertMessage(message);
      setAlertConfirm(() => onConfirm);
      setAlertVisible(true);
      setShowCancel(showCancel);
    },
    []
  );

  const fetchRecentHistory = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${params?.id}/history?limit=5`
      );
      setRecentHistory(response.data);
    } catch (error) {
      console.error("Error fetching recent history:", error);
    }
  }, [params?.id]);

  const isSubmissionAllowed = useCallback(() => {
    const { isSubmissionOpen } = getCurrentWeekDates();
    return isSubmissionOpen;
  }, []);

  // Define populateTimesheetFields first
  const populateTimesheetFields = useCallback((weekData) => {
    const newStartTimes = Array(7).fill("");
    const newEndTimes = Array(7).fill("");
    const newLunchStartTimes = Array(7).fill("");
    const newLunchEndTimes = Array(7).fill("");
    const newVacationTimes = Array(7).fill("");
    const newSickTimes = Array(7).fill("");
    const newHolidayTimes = Array(7).fill("");
    const newTotalWorkHours = Array(7).fill(0);

    weekData.days.forEach((day) => {
      const dayDate = moment(day.date);
      const dayIndex = dayDate.day();

      newStartTimes[dayIndex] = day.startTime || "";
      newEndTimes[dayIndex] = day.endTime || "";
      newLunchStartTimes[dayIndex] = day.lunchStartTime || "";
      newLunchEndTimes[dayIndex] = day.lunchEndTime || "";

      // Set hours based on type
      day.hours.forEach((hour) => {
        switch (hour.type) {
          case "vacation":
            newVacationTimes[dayIndex] = hour.amount.toString();
            break;
          case "sick":
            newSickTimes[dayIndex] = hour.amount.toString();
            break;
          case "holiday":
            newHolidayTimes[dayIndex] = hour.amount.toString();
            break;
          case "work":
            newTotalWorkHours[dayIndex] = hour.amount;
            break;
        }
      });
    });

    setStartTimes(newStartTimes);
    setEndTimes(newEndTimes);
    setLunchStartTimes(newLunchStartTimes);
    setLunchEndTimes(newLunchEndTimes);
    setVacationTimes(newVacationTimes);
    setSickTimes(newSickTimes);
    setHolidayTimes(newHolidayTimes);
    setTotalWorkHours(newTotalWorkHours);
  }, []);

  // Add timezone constant
  const TIMEZONE = "America/Phoenix";

  // 1. First, declare the helper functions
  const handleRegularEmployeeData = useCallback(
    (currentWeekData, weekDays) => {
      const savedDaysArray = Array(7).fill(false);

      currentWeekData.days.forEach((day) => {
        const dayDate = moment(day.date);
        const dayIndex = dayDate.day();

        const hasHours = day.hours.some((h) => h.amount > 0);
        savedDaysArray[dayIndex] = day.isSaved || hasHours;
      });

      console.log("Processing regular employee data:", {
        savedDays: savedDaysArray,
        daysCount: currentWeekData.days.length,
      });

      setSavedDays(savedDaysArray);
      populateTimesheetFields(currentWeekData);
    },
    [populateTimesheetFields]
  );

  const handleSpecialFormatEmployeeData = useCallback(
    (currentWeekData, weekDays) => {
      const newTimesheetData = weekDays.map((day) => {
        const dayDate = day.format("YYYY-MM-DD");
        const matchingDay = currentWeekData.days.find(
          (d) => moment(d.date).format("YYYY-MM-DD") === dayDate
        );

        if (matchingDay) {
          const totals = calculateDailyTotal(matchingDay);
          return {
            date: dayDate,
            services: Array.isArray(matchingDay.services)
              ? matchingDay.services
              : [],
            isStandBy: matchingDay.isStandBy || false,
            lunchStartTime: matchingDay.lunchStartTime || "",
            lunchEndTime: matchingDay.lunchEndTime || "",
            dailyRegularHours: totals.regularHours,
            dailyStandbyHours: totals.standbyHours,
            dailyTotalHours: totals.total,
          };
        }

        return {
          date: dayDate,
          services: [],
          isStandBy: false,
          lunchStartTime: "",
          lunchEndTime: "",
          dailyRegularHours: 0,
          dailyStandbyHours: 0,
          dailyTotalHours: 0,
        };
      });

      console.log("Processing special format data:", {
        daysProcessed: newTimesheetData.length,
        hasServices: newTimesheetData.some((d) => d.services.length > 0),
      });

      setTimesheetData(newTimesheetData);
      setWeeklyRegularHours(currentWeekData.weeklyRegularHours || 0);
      setWeeklyStandbyHours(currentWeekData.weeklyStandbyHours || 0);
      setSpecialFormatWeeklyTotal(currentWeekData.weeklyTotalHours || 0);

      const savedDaysFromBackend = newTimesheetData.map(
        (day) => Array.isArray(day.services) && day.services.length > 0
      );
      setSavedDays(savedDaysFromBackend);
    },
    [calculateDailyTotal]
  );

  // 2. Then declare initializeTimesheet
  const initializeTimesheet = useCallback(() => {
    const { weekStart, isSubmissionOpen } = getCurrentWeekDates();
    const currentWeek = getWeekDays(weekStart);

    console.log("Initializing timesheet:", {
      weekStart: weekStart.format("YYYY-MM-DD"),
      weekDays: currentWeek.map((d) => d.format("YYYY-MM-DD")),
      isSubmissionOpen,
    });

    // Initialize base timesheet data
    setCurrentWeek(currentWeek);
    setStartTimes(Array(7).fill(""));
    setEndTimes(Array(7).fill(""));
    setLunchStartTimes(Array(7).fill(""));
    setLunchEndTimes(Array(7).fill(""));
    setTotalWorkHours(Array(7).fill(0));
    setVacationTimes(Array(7).fill(""));
    setSickTimes(Array(7).fill(""));
    setHolidayTimes(Array(7).fill(""));
    setSavedDays(Array(7).fill(false));

    // Special format employee initialization
    if (isSpecialFormatEmployee) {
      const newTimesheetData = currentWeek.map((day) => ({
        date: day.format("YYYY-MM-DD"),
        services: [],
        isStandBy: false,
        lunchStartTime: "",
        lunchEndTime: "",
        dailyRegularHours: 0,
        dailyStandbyHours: 0,
        dailyTotalHours: 0,
      }));

      console.log("Initializing special format data:", {
        daysCount: newTimesheetData.length,
        firstDay: newTimesheetData[0].date,
        lastDay: newTimesheetData[6].date,
      });

      setTimesheetData(newTimesheetData);
      setWeeklyRegularHours(0);
      setWeeklyStandbyHours(0);
      setSpecialFormatWeeklyTotal(0);
    }

    setCanSubmit(!isSubmitted && isSubmissionOpen);
    setIsLoading(false);
  }, [isSpecialFormatEmployee, isSubmitted]);

  // 3. Then declare resetStates
  const resetStates = useCallback(() => {
    console.log("Resetting timesheet states");
    initializeTimesheet();
  }, [initializeTimesheet]);

  // 4. Finally, declare fetchAttendanceData
  const fetchAttendanceData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { weekStart } = getCurrentWeekDates();

      // Initialize week days immediately
      const weekDays = getWeekDays(weekStart);
      setCurrentWeek(weekDays);

      console.log("Fetching attendance:", {
        employeeId: params?.id,
        weekStart: weekStart.format("YYYY-MM-DD"),
      });

      const response = await axios.get(
        `https://sbg-backend.onrender.com/attendances/${params?.id}`,
        {
          params: {
            weekStart: weekStart.format("YYYY-MM-DD"),
          },
          timeout: 10000,
        }
      );

      const attendanceData = response.data;

      if (!Array.isArray(attendanceData)) {
        throw new Error("Invalid attendance data format");
      }

      console.log("Received attendance data:", {
        recordsCount: attendanceData.length,
        hasCurrentWeek: attendanceData.some((d) =>
          moment(d.weekStart).isSame(weekStart, "day")
        ),
      });

      if (attendanceData.length > 0) {
        const currentWeekData = attendanceData.find((data) =>
          moment(data.weekStart).isSame(weekStart, "day")
        );

        if (currentWeekData) {
          if (!isSpecialFormatEmployee) {
            handleRegularEmployeeData(currentWeekData, weekDays);
          } else {
            handleSpecialFormatEmployeeData(currentWeekData, weekDays);
          }

          setIsSubmitted(currentWeekData.isSubmitted || false);
          setCanSubmit(!currentWeekData.isSubmitted && isSubmissionAllowed());

          console.log("Successfully processed attendance data");
        } else {
          console.log("No data for current week, initializing empty timesheet");
          resetStates();
        }
      } else {
        console.log(
          "No attendance records found, initializing empty timesheet"
        );
        resetStates();
      }
    } catch (error) {
      console.error("Error fetching attendance:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      showAlert(
        "Failed to fetch timesheet data. Please try again.",
        "Error",
        null,
        false
      );

      resetStates();
    } finally {
      setIsLoading(false);
    }
  }, [
    params?.id,
    isSpecialFormatEmployee,
    handleRegularEmployeeData,
    handleSpecialFormatEmployeeData,
    isSubmissionAllowed,
    showAlert,
    resetStates,
  ]);

  // 5. useEffect remains the same
  useEffect(() => {
    console.log("Component mounted, initializing...");
    initializeTimesheet();
    fetchAttendanceData();
    fetchRecentHistory();

    const interval = setInterval(() => {
      console.log("Running periodic update...");
      fetchAttendanceData();
      fetchRecentHistory();
    }, 300000);

    return () => {
      console.log("Component unmounting, clearing interval");
      clearInterval(interval);
    };
  }, [fetchAttendanceData, fetchRecentHistory, initializeTimesheet]);

  useEffect(() => {
    fetchAttendanceData();
    fetchRecentHistory();

    const interval = setInterval(() => {
      fetchAttendanceData();
      fetchRecentHistory();
    }, 300000); // 5 minutes

    return () => clearInterval(interval);
  }, [fetchAttendanceData, fetchRecentHistory]);

  useEffect(() => {
    if (!isSpecialFormatEmployee) {
      const calculateTotalWorkHours = () => {
        const minuteToDecimal = (minutes) => {
          const chart = {
            1: 0.02,
            2: 0.03,
            3: 0.05,
            4: 0.07,
            5: 0.08,
            6: 0.1,
            7: 0.12,
            8: 0.13,
            9: 0.15,
            10: 0.17,
            11: 0.18,
            12: 0.2,
            13: 0.22,
            14: 0.23,
            15: 0.25,
            16: 0.27,
            17: 0.28,
            18: 0.3,
            19: 0.32,
            20: 0.33,
            21: 0.35,
            22: 0.37,
            23: 0.38,
            24: 0.4,
            25: 0.42,
            26: 0.43,
            27: 0.45,
            28: 0.47,
            29: 0.48,
            30: 0.5,
            31: 0.52,
            32: 0.53,
            33: 0.55,
            34: 0.57,
            35: 0.58,
            36: 0.6,
            37: 0.62,
            38: 0.63,
            39: 0.65,
            40: 0.67,
            41: 0.68,
            42: 0.7,
            43: 0.72,
            44: 0.73,
            45: 0.75,
            46: 0.77,
            47: 0.78,
            48: 0.8,
            49: 0.82,
            50: 0.83,
            51: 0.85,
            52: 0.87,
            53: 0.88,
            54: 0.9,
            55: 0.92,
            56: 0.93,
            57: 0.95,
            58: 0.97,
            59: 0.98,
            60: 1.0,
          };
          return chart[minutes] || 0;
        };

        const newTotalWorkHours = startTimes.map((startTime, i) => {
          if (startTime && endTimes[i]) {
            const start = moment.tz(startTime, "h:mm A", "America/Phoenix");
            const end = moment.tz(endTimes[i], "h:mm A", "America/Phoenix");

            if (end.isBefore(start)) {
              end.add(1, "day");
            }

            const workDuration = moment.duration(end.diff(start));
            let workMinutes = workDuration.asMinutes();

            if (lunchStartTimes[i] && lunchEndTimes[i]) {
              const lunchStart = moment.tz(
                lunchStartTimes[i],
                "h:mm A",
                "America/Phoenix"
              );
              const lunchEnd = moment.tz(
                lunchEndTimes[i],
                "h:mm A",
                "America/Phoenix"
              );

              if (lunchEnd.isBefore(lunchStart)) {
                lunchEnd.add(1, "day");
              }

              const lunchDuration = moment.duration(lunchEnd.diff(lunchStart));
              workMinutes -= lunchDuration.asMinutes();
            }

            const workHours = Math.floor(workMinutes / 60);
            const remainingMinutes = workMinutes % 60;
            return workHours + minuteToDecimal(remainingMinutes);
          } else {
            return 0;
          }
        });

        const newTotalSpecialHours = startTimes.map((_, i) => {
          const vacationHours = parseFloat(vacationTimes[i]) || 0;
          const sickHours = parseFloat(sickTimes[i]) || 0;
          const holidayHours = parseFloat(holidayTimes[i]) || 0;
          return vacationHours + sickHours + holidayHours;
        });

        setTotalWorkHours(
          newTotalWorkHours.map(
            (workHours, i) => workHours + newTotalSpecialHours[i]
          )
        );

        const newWeeklyTotalHours = newTotalWorkHours.reduce(
          (acc, hours, i) => acc + hours + newTotalSpecialHours[i],
          0
        );
        setWeeklyTotalHours(newWeeklyTotalHours);
      };

      calculateTotalWorkHours();
    }
  }, [
    startTimes,
    endTimes,
    lunchStartTimes,
    lunchEndTimes,
    vacationTimes,
    sickTimes,
    holidayTimes,
    isSpecialFormatEmployee,
  ]);

  const validateTime = useCallback((time) => {
    if (!time) return true;
    const timeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i;
    return timeRegex.test(time);
  }, []);

  const prepareDayData = useCallback(
    (dayData) => {
      console.log("Incoming dayData:", dayData);
      const preparedData = {
        employeeId: params?.id,
        employeeName: params?.name,
        date: dayData.date ? moment(dayData.date).format("YYYY-MM-DD") : "",
        services: (dayData.services || []).map((service) => ({
          service: service.service,
          startTime: service.startTime || null,
          endTime: service.endTime || null,
        })),
        isStandBy: dayData.isStandBy,
        lunchStartTime: dayData.lunchStartTime || null,
        lunchEndTime: dayData.lunchEndTime || null,
      };

      const validateData = (data) => {
        const errors = [];

        if (!data.employeeId) errors.push("Employee ID is missing");
        if (!data.employeeName) errors.push("Employee Name is missing");
        if (!data.date) errors.push("Date is missing");

        if (!Array.isArray(data.services) || data.services.length === 0) {
          errors.push("Services array is empty");
        } else {
          data.services.forEach((service, index) => {
            if (!service.service)
              errors.push(
                `Service description is missing for service ${index + 1}`
              );
            if (!service.startTime)
              errors.push(`Start time is missing for service ${index + 1}`);
            if (!service.endTime)
              errors.push(`End time is missing for service ${index + 1}`);
          });
        }

        if (data.isStandBy === undefined)
          errors.push("isStandBy flag is missing");

        if (!data.isStandBy) {
          if (data.lunchStartTime && !data.lunchEndTime)
            errors.push("Lunch end time is missing");
          if (!data.lunchStartTime && data.lunchEndTime)
            errors.push("Lunch start time is missing");
        }

        return errors;
      };

      const validationErrors = validateData(preparedData);

      if (validationErrors.length > 0) {
        console.error("Validation errors:", validationErrors);
        throw new Error(`Invalid data: ${validationErrors.join(", ")}`);
      }

      console.log("Prepared day data:", JSON.stringify(preparedData, null, 2));
      return preparedData;
    },
    [params?.id, params?.name]
  );

  const handleSaveDay = useCallback(
    async (index, dayData) => {
      if (!canSubmit || isSubmitted) {
        showAlert(
          "This timesheet has already been submitted and cannot be modified.",
          "Submission Closed",
          null,
          false
        );
        return;
      }

      if (isSpecialFormatEmployee) {
        if (!dayData) {
          showAlert(
            "Day data is missing. Please try again.",
            "Error",
            null,
            false
          );
          return;
        }

        try {
          const preparedDayData = prepareDayData(dayData);
          console.log(
            "Sending data to saveSpecialDay:",
            JSON.stringify(preparedDayData, null, 2)
          );

          const response = await axios.post(
            "https://sbg-backend.onrender.com/saveSpecialDay",
            preparedDayData
          );

          console.log("Response from saveSpecialDay:", response.data);

          if (response.status === 200) {
            if (response.data.isSubmitted) {
              setIsSubmitted(true);
              setCanSubmit(false);
              showAlert(
                "This timesheet has been submitted and can no longer be modified.",
                "Timesheet Locked",
                null,
                false
              );
            } else {
              const newSavedDays = [...savedDays];
              newSavedDays[index] = true;
              setSavedDays(newSavedDays);
              showAlert("Day saved successfully", "Success", null, false);
            }
          } else {
            showAlert(
              `Failed to save day. Server responded with status ${response.status}`,
              "Error",
              null,
              false
            );
          }
        } catch (error) {
          console.error("Error saving special day:", error);
          if (error.message.startsWith("Invalid data:")) {
            showAlert(error.message, "Validation Error", null, false);
          } else {
            console.error(
              "Error response:",
              error.response ? error.response.data : "No response data"
            );
            showAlert(
              `An error occurred. ${error.message}`,
              "Error",
              null,
              false
            );
          }
        }
      } else {
        const timesToValidate = [
          startTimes[index],
          endTimes[index],
          lunchStartTimes[index],
          lunchEndTimes[index],
        ];

        const invalidTimes = timesToValidate.filter(
          (time) => time && !validateTime(time)
        );

        if (invalidTimes.length > 0) {
          showAlert(
            "Please enter valid times in the format HH:MM AM/PM (e.g., 09:00 AM).",
            "Invalid Time Format",
            null,
            false
          );
          return;
        }

        const vacationHours = parseFloat(vacationTimes[index]) || 0;
        const sickHours = parseFloat(sickTimes[index]) || 0;
        const holidayHours = parseFloat(holidayTimes[index]) || 0;
        const totalSpecialHours = vacationHours + sickHours + holidayHours;

        if (totalSpecialHours > 8) {
          showAlert(
            "The combined vacation, sick, and holiday hours cannot exceed 8 hours for a single day.",
            "Invalid Hours",
            null,
            false
          );
          return;
        }

        try {
          const { weekStart } = getCurrentWeekDates();
          const dayDate = weekStart.clone().add(index, "days");

          const dayData = {
            employeeId: params?.id,
            employeeName: params?.name,
            dayOfWeek: dayDate.format("dddd"),
            date: dayDate.format("YYYY-MM-DD"),
            startTime: startTimes[index] || null,
            endTime: endTimes[index] || null,
            lunchStartTime: lunchStartTimes[index] || null,
            lunchEndTime: lunchEndTimes[index] || null,
            hours: [
              { type: "work", amount: totalWorkHours[index] },
              { type: "vacation", amount: vacationHours },
              { type: "sick", amount: sickHours },
              { type: "holiday", amount: holidayHours },
            ],
            weekStart: weekStart.format("YYYY-MM-DD"),
          };

          const response = await axios.post(
            "https://sbg-backend.onrender.com/saveDay",
            dayData
          );

          if (response.status === 200) {
            if (response.data.isSubmitted) {
              setIsSubmitted(true);
              setCanSubmit(false);
              showAlert(
                "This timesheet has been submitted and can no longer be modified.",
                "Timesheet Locked",
                null,
                false
              );
            } else {
              const newSavedDays = [...savedDays];
              newSavedDays[index] = true;
              setSavedDays(newSavedDays);
              showAlert("Day saved successfully", "Success", null, false);
            }
          } else {
            showAlert(
              "Failed to save day. Please try again.",
              "Error",
              null,
              false
            );
          }
        } catch (error) {
          console.error("Error saving day:", error);
          showAlert(
            "An error occurred. Please try again.",
            "Error",
            null,
            false
          );
        }
      }
    },
    [
      canSubmit,
      isSubmitted,
      isSpecialFormatEmployee,
      prepareDayData,
      savedDays,
      showAlert,
      startTimes,
      endTimes,
      lunchStartTimes,
      lunchEndTimes,
      vacationTimes,
      sickTimes,
      holidayTimes,
      totalWorkHours,
      params?.id,
      params?.name,
      validateTime,
    ]
  );

  const handleTermsScroll = useCallback((event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const paddingToBottom = 20;
    if (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    ) {
      setHasScrolledToBottom(true);
    }
  }, []);

  const handleTermsCheckboxPress = useCallback(() => {
    if (!hasAgreedToTerms) {
      setIsTermsModalVisible(true);
    } else {
      setHasAgreedToTerms(false);
    }
  }, [hasAgreedToTerms]);

  const handleAgreeToTerms = useCallback(() => {
    setHasAgreedToTerms(true);
    setIsTermsModalVisible(false);
  }, []);

  const createConfirmationMessage = useCallback(() => {
    let message = `I, ${
      params?.name
    }, hereby certify that I have reviewed and verified the accuracy of my timesheet for the week ${currentWeek[0].format(
      "MM/DD/YYYY"
    )} to ${currentWeek[6].format("MM/DD/YYYY")}.\n\n`;

    let weeklyTotal = 0;

    if (isSpecialFormatEmployee) {
      // Calculate total using the same logic as the UI
      weeklyTotal = timesheetData.reduce((total, dayData, index) => {
        if (!dayData || !Array.isArray(dayData.services)) return total;

        // Calculate service hours
        const serviceTotal = dayData.services.reduce(
          (sum, service) =>
            sum + calculateTotalTime(service.startTime, service.endTime),
          0
        );

        // Calculate lunch duration if applicable
        let lunchDuration = 0;
        if (
          !dayData.isStandBy &&
          dayData.lunchStartTime &&
          dayData.lunchEndTime
        ) {
          const lunchStart = moment(dayData.lunchStartTime, [
            "h:mm A",
            "HH:mm",
          ]);
          const lunchEnd = moment(dayData.lunchEndTime, ["h:mm A", "HH:mm"]);

          // Check if lunch overlaps with any service
          const lunchOverlaps = dayData.services.some((service) => {
            const serviceStart = moment(service.startTime, ["h:mm A", "HH:mm"]);
            const serviceEnd = moment(service.endTime, ["h:mm A", "HH:mm"]);
            return (
              lunchStart.isBetween(serviceStart, serviceEnd, null, "[]") ||
              lunchEnd.isBetween(serviceStart, serviceEnd, null, "[]") ||
              (lunchStart.isSameOrBefore(serviceStart) &&
                lunchEnd.isSameOrAfter(serviceEnd))
            );
          });

          if (lunchOverlaps) {
            lunchDuration = calculateTotalTime(
              dayData.lunchStartTime,
              dayData.lunchEndTime
            );
          }
        }

        const dailyTotal = dayData.isStandBy
          ? 24
          : Math.max(0, serviceTotal - lunchDuration);

        return total + dailyTotal;
      }, 0);
    } else {
      // Regular timesheet calculation remains the same
      weeklyTotal = totalWorkHours.reduce(
        (sum, hours) => sum + (hours || 0),
        0
      );
    }

    message += `Weekly Total Hours: ${weeklyTotal.toFixed(2)}\n\n`;
    message +=
      "By proceeding, you will be asked to provide your electronic signature to submit this timesheet. Do you wish to continue?";

    return message;
  }, [
    currentWeek,
    isSpecialFormatEmployee,
    timesheetData,
    totalWorkHours,
    params?.name,
    calculateTotalTime,
  ]);

  const handleAttendanceSubmit = useCallback(() => {
    if (!canSubmit || isSubmitted) {
      showAlert(
        "This timesheet has already been submitted and cannot be modified.",
        "Submission Closed",
        null,
        false
      );
      return;
    }

    if (!isSignatureChecked || !hasAgreedToTerms) {
      showAlert(
        "Please check both the acknowledgment and agree to the terms and conditions before submitting.",
        "Acknowledgment Required",
        null,
        false
      );
      return;
    }

    const confirmationMessage = createConfirmationMessage();

    showAlert(
      confirmationMessage,
      "Confirm Submission",
      () => {
        setIsSignatureModalVisible(true);
      },
      true
    );
  }, [
    canSubmit,
    isSubmitted,
    isSignatureChecked,
    hasAgreedToTerms,
    showAlert,
    createConfirmationMessage,
  ]);

  const handleSignatureEnd = useCallback(() => {
    if (signaturePadRef.current) {
      const isEmpty = signaturePadRef.current.isEmpty();
      setIsSignaturePadEmpty(isEmpty);
      if (!isEmpty) {
        setSignatureData(signaturePadRef.current.toDataURL());
      }
    }
  }, []);

  const handleSignatureCancel = useCallback(() => {
    setIsSignatureModalVisible(false);
    setSignatureData(null);
    setIsSignaturePadEmpty(true);
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  }, []);

  const submitAttendance = useCallback(async () => {
    setIsSubmitting(true);

    try {
      console.log("Starting attendance submission process...");

      // Basic validation
      if (!params?.id || !params?.name || !signatureData) {
        throw new Error(
          "Missing required fields: employee ID, name, or signature"
        );
      }

      const { weekStart } = getCurrentWeekDates();

      // Helper function to safely parse hours with logging
      const parseHours = (value, label) => {
        const parsed = parseFloat(value);
        const result = Math.max(
          0,
          isNaN(parsed) ? 0 : parseFloat(parsed.toFixed(2))
        );
        console.log(`Parsing ${label}:`, { raw: value, parsed, result });
        return result;
      };

      // Initialize days data array
      let daysData;

      if (!isSpecialFormatEmployee) {
        console.log("Processing regular format timesheet...");

        daysData = startTimes
          .map((startTime, index) => {
            console.log(`\nProcessing day ${index + 1}:`);
            console.log("Raw input values:", {
              startTime,
              endTime: endTimes[index],
              lunchStart: lunchStartTimes[index],
              lunchEnd: lunchEndTimes[index],
              totalWork: totalWorkHours[index],
              vacation: vacationTimes[index],
              sick: sickTimes[index],
              holiday: holidayTimes[index],
            });

            // Parse all hours first
            const vacationHours = parseHours(
              vacationTimes[index],
              `day ${index + 1} vacation`
            );
            const sickHours = parseHours(
              sickTimes[index],
              `day ${index + 1} sick`
            );
            const holidayHours = parseHours(
              holidayTimes[index],
              `day ${index + 1} holiday`
            );
            const rawWorkHours = parseHours(
              totalWorkHours[index],
              `day ${index + 1} raw work`
            );

            // Calculate total special hours
            const specialHours = vacationHours + sickHours + holidayHours;
            console.log(`Day ${index + 1} special hours total:`, specialHours);

            // Determine work hours - if there are special hours, work hours should be 0
            const workHours = specialHours > 0 ? 0 : rawWorkHours;
            console.log(`Day ${index + 1} final work hours:`, workHours);

            // Skip empty days
            const hasSomeHours = workHours > 0 || specialHours > 0;
            if (!hasSomeHours) {
              console.log(`Day ${index + 1} has no hours, skipping`);
              return null;
            }

            // Validate total daily hours
            const totalDayHours = workHours + specialHours;
            console.log(`Day ${index + 1} total hours:`, totalDayHours);

            if (totalDayHours > 24) {
              throw new Error(
                `Total hours for day ${
                  index + 1
                } cannot exceed 24 hours (current: ${totalDayHours})`
              );
            }

            const dayData = {
              dayOfWeek: weekStart.clone().add(index, "days").format("dddd"),
              date: weekStart.clone().add(index, "days").format("YYYY-MM-DD"),
              startTime: startTime ? String(startTime).trim() : null,
              endTime: endTimes[index] ? String(endTimes[index]).trim() : null,
              lunchStartTime: lunchStartTimes[index]
                ? String(lunchStartTimes[index]).trim()
                : null,
              lunchEndTime: lunchEndTimes[index]
                ? String(lunchEndTimes[index]).trim()
                : null,
              hours: [
                { type: "work", amount: workHours },
                { type: "vacation", amount: vacationHours },
                { type: "sick", amount: sickHours },
                { type: "holiday", amount: holidayHours },
              ],
            };

            console.log(
              `Day ${index + 1} final data:`,
              JSON.stringify(dayData, null, 2)
            );
            return dayData;
          })
          .filter(Boolean);

        // Log final days data
        console.log("\nFinal days data:", JSON.stringify(daysData, null, 2));

        // Calculate and log weekly total
        const weeklyTotal = daysData.reduce((total, day) => {
          const dayTotal = day.hours.reduce(
            (sum, hour) => sum + hour.amount,
            0
          );
          console.log(`Day total (${day.dayOfWeek}):`, dayTotal);
          return total + dayTotal;
        }, 0);

        console.log("Weekly total hours:", weeklyTotal);

        // Prepare final attendance data
        const attendanceData = {
          employeeId: params.id,
          employeeName: params.name.trim(),
          date: moment().tz("America/Phoenix").format("YYYY-MM-DD"),
          weekStart: weekStart.format("YYYY-MM-DD"),
          weeklyTotalHours: weeklyTotal,
          days: daysData,
          signature: signatureData,
          signatureHash: CryptoJS.SHA256(signatureData).toString(),
          isSubmitted: true,
          isSpecialFormat: false,
        };

        console.log(
          "\nFinal attendance data:",
          JSON.stringify(attendanceData, null, 2)
        );

        // Make the API call
        console.log("Submitting to API...");
        const response = await axios.post(
          "https://sbg-backend.onrender.com/attendance",
          attendanceData,
          {
            timeout: 30000,
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log("API Response:", response.status);

        if (response.status === 200) {
          setIsSubmitted(true);
          setCanSubmit(false);
          showAlert(
            "Attendance submitted successfully. You can no longer modify this timesheet.",
            "Success",
            () => {
              fetchAttendanceData();
            },
            false
          );
        }
      } else {
        console.log("Processing special format timesheet...");

        // Validate timesheet data
        if (!Array.isArray(timesheetData) || timesheetData.length !== 7) {
          throw new Error("Invalid timesheet structure");
        }

        // Format the days data with proper validation
        const formattedDays = timesheetData.map((day, index) => {
          if (!day) {
            return {
              date: moment(currentWeek[index]).toDate(), // Convert to Date object
              services: [],
              isStandBy: false,
              lunchStartTime: null,
              lunchEndTime: null,
              serviceHours: 0,
              standByHours: 0,
              totalHours: 0,
            };
          }

          // Calculate totals for the day
          const dayTotal = calculateDailyTotal(day);

          // Format services with validation
          const services = (day.services || []).map((service) => ({
            service: service.service?.trim() || "",
            startTime: service.startTime,
            endTime: service.endTime,
            totalTime: calculateTotalTime(service.startTime, service.endTime),
          }));

          return {
            date: moment(day.date).toDate(), // Convert to Date object
            dayOfWeek: moment(day.date).format("dddd"),
            services: services,
            isStandBy: Boolean(day.isStandBy),
            lunchStartTime: day.lunchStartTime || null,
            lunchEndTime: day.lunchEndTime || null,
            serviceHours: dayTotal.regularHours,
            standByHours: dayTotal.standbyHours,
            totalHours: dayTotal.total,
          };
        });

        // Calculate weekly totals
        const weeklyTotals = formattedDays.reduce(
          (totals, day) => ({
            regularHours: totals.regularHours + day.serviceHours,
            standbyHours: totals.standbyHours + day.standByHours,
            total: totals.total + day.totalHours,
          }),
          { regularHours: 0, standbyHours: 0, total: 0 }
        );

        // Prepare submission data
        const attendanceData = {
          employeeId: params.id,
          employeeName: params.name.trim(),
          date: new Date(), // Current date as Date object
          weekStart: moment(weekStart).toDate(), // Convert to Date object
          weeklyTotalHours: parseFloat(weeklyTotals.total.toFixed(2)),
          days: formattedDays,
          signature: signatureData,
          signatureVerificationHash: CryptoJS.SHA256(signatureData).toString(), // Match schema field name
          isSubmitted: true,
          isSpecialFormat: true,
          submittedDate: new Date(), // Add submittedDate
          timezone: "America/Phoenix", // Add timezone
        };

        console.log("Submitting special format timesheet:", {
          employeeId: attendanceData.employeeId,
          date: attendanceData.date,
          weekStart: attendanceData.weekStart,
          totalHours: attendanceData.weeklyTotalHours,
        });

        // Submit to API using the special endpoint
        const response = await axios.post(
          "https://sbg-backend.onrender.com/attendance/special",
          attendanceData,
          {
            timeout: 30000,
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 200) {
          setIsSubmitted(true);
          setCanSubmit(false);
          showAlert(
            "Special format timesheet submitted successfully. You can no longer modify this timesheet.",
            "Success",
            () => {
              fetchAttendanceData();
            },
            false
          );
        }
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      console.error("Error details:", {
        message: error.message,
        response: error.response?.data,
        stack: error.stack,
      });

      let errorMessage = "Failed to submit attendance: ";
      if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "An unexpected error occurred. Please try again.";
      }

      showAlert(errorMessage, "Error", null, false);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    params?.id,
    params?.name,
    signatureData,
    isSpecialFormatEmployee,
    timesheetData,
    startTimes,
    endTimes,
    lunchStartTimes,
    lunchEndTimes,
    vacationTimes,
    sickTimes,
    holidayTimes,
    totalWorkHours,
    showAlert,
    fetchAttendanceData,
    getCurrentWeekDates,
  ]);

  const handleSignatureConfirm = useCallback(() => {
    if (signatureData && !isSignaturePadEmpty) {
      setIsSignatureModalVisible(false);
      submitAttendance();
      // Clear signature data
      setSignatureData(null);
      if (signaturePadRef.current) {
        signaturePadRef.current.clear();
      }
    } else {
      console.error("Please provide a valid signature before confirming.");
      showAlert(
        "Please provide a valid signature before confirming.",
        "Invalid Signature",
        null,
        false
      );
    }
  }, [signatureData, isSignaturePadEmpty, submitAttendance, showAlert]);

  const toggleColorScheme = useCallback(() => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  }, []);

  const handleTimeChange = useCallback((index, timeType, newValue) => {
    const updater = {
      start: setStartTimes,
      end: setEndTimes,
      lunchStart: setLunchStartTimes,
      lunchEnd: setLunchEndTimes,
    }[timeType];

    updater((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = newValue;
      return newTimes;
    });
  }, []);

  const updateSpecialTimesheetData = useCallback(
    (dayIndex, serviceIndex, field, value) => {
      setTimesheetData((prevData) => {
        const newData = [...prevData];

        if (!newData[dayIndex]) {
          newData[dayIndex] = {
            date: currentWeek[dayIndex].format("YYYY-MM-DD"),
            services: [],
            isStandBy: false,
            lunchStartTime: "",
            lunchEndTime: "",
            dailyRegularHours: 0,
            dailyStandbyHours: 0,
            dailyTotalHours: 0,
          };
        }

        if (!Array.isArray(newData[dayIndex].services)) {
          newData[dayIndex].services = [];
        }

        if (serviceIndex === null) {
          newData[dayIndex] = { ...newData[dayIndex], [field]: value };
        } else {
          newData[dayIndex].services[serviceIndex] = {
            ...newData[dayIndex].services[serviceIndex],
            [field]: value,
          };

          if (field === "startTime" || field === "endTime") {
            const service = newData[dayIndex].services[serviceIndex];
            service.totalTime = calculateTotalTime(
              service.startTime,
              service.endTime
            );
          }
        }

        calculateDailyAndWeeklyTotals(newData);

        return newData;
      });
    },
    [currentWeek]
  );

  const handleWeeklyTotalChange = useCallback((newWeeklyTotal) => {
    setSpecialFormatWeeklyTotal(newWeeklyTotal);
  }, []);

  const calculateDailyAndWeeklyTotals = useCallback(
    (data) => {
      let weeklyRegularHours = 0;
      let weeklyStandbyHours = 0;
      let totalWeeklyHours = 0;

      const updatedData = data.map((day, index) => {
        // Ensure day is initialized
        if (!day) {
          day = initializeDayData(index);
        }

        // Ensure services array exists
        if (!Array.isArray(day.services)) {
          day.services = [];
        }

        const dailyRegularHours = day.services.reduce(
          (total, service) => total + (service.totalTime || 0),
          0
        );

        const dailyStandbyHours = day.isStandBy
          ? Math.max(24 - dailyRegularHours, 0)
          : 0;

        weeklyRegularHours += dailyRegularHours;
        weeklyStandbyHours += dailyStandbyHours;
        totalWeeklyHours += dailyRegularHours + dailyStandbyHours;

        return {
          ...day,
          dailyRegularHours,
          dailyStandbyHours,
          dailyTotalHours: dailyRegularHours + dailyStandbyHours,
        };
      });

      setTimesheetData(updatedData);
      setWeeklyRegularHours(weeklyRegularHours);
      setWeeklyStandbyHours(weeklyStandbyHours);
      setSpecialFormatWeeklyTotal(totalWeeklyHours);

      handleWeeklyTotalChange(totalWeeklyHours);
    },
    [handleWeeklyTotalChange, currentWeek]
  );

  const initializeDayData = useCallback(
    (dayIndex) => ({
      date: currentWeek[dayIndex]?.format("YYYY-MM-DD") || "",
      services: [],
      isStandBy: false,
      lunchStartTime: "",
      lunchEndTime: "",
      dailyRegularHours: 0,
      dailyStandbyHours: 0,
      dailyTotalHours: 0,
    }),
    [currentWeek]
  );

  const addServiceToDay = useCallback(
    (dayIndex) => {
      setTimesheetData((prevData) => {
        // Create a deep copy of the entire timesheet data
        const newData = prevData.map((day) => {
          if (!day) return initializeDayData(dayIndex);

          return {
            ...day,
            // Deep copy the services array and its contents
            services: Array.isArray(day.services)
              ? day.services.map((service) => ({
                  ...service,
                  startTime: service.startTime || "",
                  endTime: service.endTime || "",
                  service: service.service || "",
                  totalTime: service.totalTime || 0,
                }))
              : [],
          };
        });

        // Ensure the target day exists
        if (!newData[dayIndex]) {
          newData[dayIndex] = initializeDayData(dayIndex);
        }

        // Add the new service with a unique identifier
        const newService = {
          service: "",
          startTime: "",
          endTime: "",
          totalTime: 0,
          _id: `new_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        };

        // Ensure services array exists and add the new service
        newData[dayIndex].services = Array.isArray(newData[dayIndex].services)
          ? [...newData[dayIndex].services, newService]
          : [newService];

        console.log("Updated timesheet data:", {
          dayIndex,
          servicesCount: newData[dayIndex].services.length,
          newService,
        });

        return newData;
      });
    },
    [initializeDayData]
  );

  const removeServiceFromDay = useCallback(
    (dayIndex, serviceIndex) => {
      setTimesheetData((prevData) => {
        const newData = [...prevData];

        // Ensure newData[dayIndex] exists
        if (!newData[dayIndex]) {
          newData[dayIndex] = initializeDayData(dayIndex);
        }

        // Ensure services array exists
        if (!Array.isArray(newData[dayIndex].services)) {
          newData[dayIndex].services = [];
        }

        // Remove the service
        newData[dayIndex].services.splice(serviceIndex, 1);

        // If services array is empty after removal
        if (newData[dayIndex].services.length === 0) {
          // Option 1: Reset the day's data to default values
          newData[dayIndex] = initializeDayData(dayIndex);

          // Option 2: Remove the day entirely (use with caution)
          // newData[dayIndex] = undefined;
        }

        calculateDailyAndWeeklyTotals(newData);
        return newData;
      });
    },
    [calculateDailyAndWeeklyTotals]
  );

  const confirmSubmission = useCallback(async () => {
    try {
      if (!params?.id || !params?.name || !signatureData) {
        showAlert(
          "Missing required fields: employee ID, name, or signature",
          "Error",
          null,
          false
        );
        return;
      }

      if (isSpecialFormatEmployee) {
        const formattedTotal = parseFloat(weeklyTotalHours.toFixed(2));
        const weekStartDate = moment(currentWeek[0]).format("MM/DD/YYYY");
        const weekEndDate = moment(currentWeek[6]).format("MM/DD/YYYY");

        const confirmationMessage = `I, ${params.name}, hereby certify that I have reviewed and verified the accuracy of my timesheet for the week ${weekStartDate} to ${weekEndDate}.\n\nWeekly Total Hours: ${formattedTotal}\n\nBy proceeding, you will be asked to provide your electronic signature to submit this timesheet. Do you wish to continue?`;

        const confirmed = await showAlert(
          confirmationMessage,
          "Confirm Submission",
          ["Cancel", "Submit"],
          true
        );

        if (confirmed) {
          setIsSubmitting(true);
          try {
            await submitAttendance();
            showAlert(
              "Timesheet submitted successfully!",
              "Success",
              null,
              false
            );
          } catch (error) {
            console.error("Error submitting timesheet:", error);
            showAlert(
              "Failed to submit timesheet. Please try again.",
              "Error",
              null,
              false
            );
          } finally {
            setIsSubmitting(false);
          }
        }
      } else {
        // Regular timesheet logic...
      }
    } catch (error) {
      console.error("Error in confirmSubmission:", error);
      showAlert(
        "An error occurred while processing the timesheet. Please try again.",
        "Error",
        null,
        false
      );
    }
  }, [
    params?.id,
    params?.name,
    signatureData,
    isSpecialFormatEmployee,
    weeklyTotalHours,
    currentWeek,
    submitAttendance,
    showAlert,
  ]);

  const submitSpecialFormatTimesheet = async () => {
    try {
      setIsSubmitting(true);
      console.log("Starting special format timesheet submission...");

      // ... existing validation and data preparation ...

      // Submit to API with updated endpoint
      const response = await axios.post(
        "https://sbg-backend.onrender.com/attendance/special",
        submissionData,
        {
          timeout: 30000,
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setIsSubmitted(true);
        setCanSubmit(false);
        showAlert(
          "Special format timesheet submitted successfully. You can no longer modify this timesheet.",
          "Success",
          () => {
            fetchAttendanceData();
          },
          false
        );
      } else {
        throw new Error(`Server responded with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting special format timesheet:", error);
      let errorMessage = "Failed to submit special format timesheet: ";

      if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += "An unexpected error occurred";
      }

      showAlert(errorMessage, "Error", null, false);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#121212" : "#F8F9FA"}
      />
      <View
        style={[styles.header, colorScheme === "dark" && styles.headerDark]}
      >
        <BackButton color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"} />
        <Text
          style={[
            styles.headerText,
            colorScheme === "dark" && styles.headerTextDark,
          ]}
        >
          Timesheet
        </Text>
        <TouchableOpacity
          onPress={toggleColorScheme}
          style={styles.themeToggle}
        >
          <Feather
            name={colorScheme === "dark" ? "sun" : "moon"}
            size={24}
            color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.scrollView}>
        <View style={[styles.content, isDesktop && styles.desktopContent]}>
          <View style={[styles.column, isDesktop && styles.leftColumn]}>
            <View
              style={[
                styles.welcomeContainer,
                colorScheme === "dark" && styles.welcomeContainerDark,
              ]}
            >
              <Text
                style={[
                  styles.welcomeText,
                  colorScheme === "dark" && styles.welcomeTextDark,
                ]}
              >
                Welcome, {params?.name}
              </Text>
              <Text
                style={[
                  styles.dateRangeText,
                  colorScheme === "dark" && styles.dateRangeTextDark,
                ]}
              >
                Current Timesheet:{" "}
                {currentWeek[0] ? currentWeek[0].format("MMMM DD") : ""} -{" "}
                {currentWeek[6] ? currentWeek[6].format("MMMM DD, YYYY") : ""}
              </Text>
              {moment().tz("America/Phoenix").day() === 1 &&
                moment().tz("America/Phoenix").hour() < 12 &&
                currentWeek[0] && (
                  <Text style={styles.timesheetMessage}>
                    {getTimesheetMessage(currentWeek[0])}
                  </Text>
                )}
            </View>

            <View
              style={[
                styles.infoCard,
                colorScheme === "dark" && styles.infoCardDark,
              ]}
            >
              <MaterialIcons
                name="info-outline"
                size={24}
                color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
                style={styles.infoIcon}
              />
              <View style={styles.infoTextContainer}>
                <Text
                  style={[
                    styles.infoTextHeader,
                    colorScheme === "dark" && styles.infoTextHeaderDark,
                  ]}
                >
                  Timesheets are due on{" "}
                  <Text style={styles.boldText}>Mondays before 12:00 noon</Text>
                  .
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Timesheets not submitted on time may be processed in the
                  subsequent pay period.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Payroll staff will attempt to contact you if your timesheet
                  is missing.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Hours over 40 per week will be paid as overtime (1.5x hourly
                  rate).
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Please check your entries for accuracy before submitting.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Recent attendance history is displayed below.
                </Text>
                <Text
                  style={[
                    styles.infoText,
                    colorScheme === "dark" && styles.infoTextDark,
                  ]}
                >
                  • Personal leave requests can be submitted below.
                </Text>
              </View>
            </View>

            {isSpecialFormatEmployee ? (
              <SpecialFormatTimesheet
                currentWeek={currentWeek}
                colorScheme={colorScheme}
                onSaveDay={handleSaveDay}
                savedDays={savedDays}
                isSubmitted={isSubmitted}
                timesheetData={timesheetData}
                updateSpecialTimesheetData={updateSpecialTimesheetData}
                addServiceToDay={addServiceToDay}
                removeServiceFromDay={removeServiceFromDay}
                employeeId={params?.id}
                employeeName={params?.name}
                prepareDayData={prepareDayData}
                onWeeklyTotalChange={handleWeeklyTotalChange}
              />
            ) : (
              currentWeek.map((day, index) => (
                <View
                  key={index}
                  style={[
                    styles.dayCard,
                    colorScheme === "dark" && styles.dayCardDark,
                    day.isSame(moment().tz("America/Phoenix"), "day") &&
                      styles.currentDayCard,
                  ]}
                >
                  <Text
                    style={[
                      styles.dayText,
                      colorScheme === "dark" && styles.dayTextDark,
                    ]}
                  >
                    {day.format("dddd, MMMM DD")}
                  </Text>
                  {savedDays[index] && (
                    <View style={styles.savedBadge}>
                      <Text style={styles.savedBadgeText}>Saved</Text>
                    </View>
                  )}
                  <View style={styles.timeInputsRow}>
                    <TimeInput
                      label="Start Time"
                      time={startTimes[index]}
                      onChange={(newValue) =>
                        handleTimeChange(index, "start", newValue)
                      }
                      disabled={!canSubmit || isSubmitted}
                      colorScheme={colorScheme}
                    />
                    <TimeInput
                      label="End Time"
                      time={endTimes[index]}
                      onChange={(newValue) =>
                        handleTimeChange(index, "end", newValue)
                      }
                      disabled={!canSubmit || isSubmitted}
                      colorScheme={colorScheme}
                    />
                  </View>
                  <View style={styles.timeInputsRow}>
                    <TimeInput
                      label="Lunch Start"
                      time={lunchStartTimes[index]}
                      onChange={(newValue) =>
                        handleTimeChange(index, "lunchStart", newValue)
                      }
                      disabled={!canSubmit || isSubmitted}
                      colorScheme={colorScheme}
                    />
                    <TimeInput
                      label="Lunch End"
                      time={lunchEndTimes[index]}
                      onChange={(newValue) =>
                        handleTimeChange(index, "lunchEnd", newValue)
                      }
                      disabled={!canSubmit || isSubmitted}
                      colorScheme={colorScheme}
                    />
                  </View>
                  <View style={styles.specialHoursContainer}>
                    <View style={styles.specialHoursWrapper}>
                      <Text
                        style={[
                          styles.inputLabel,
                          colorScheme === "dark" && styles.inputLabelDark,
                        ]}
                      >
                        Vacation
                      </Text>
                      <TextInput
                        style={[
                          styles.specialHoursInput,
                          colorScheme === "dark" &&
                            styles.specialHoursInputDark,
                        ]}
                        placeholder="0"
                        placeholderTextColor={
                          colorScheme === "dark"
                            ? "rgba(255, 255, 255, 0.5)"
                            : "rgba(0, 0, 0, 0.5)"
                        }
                        value={vacationTimes[index] || ""}
                        onChangeText={(text) => {
                          const newVacationTimes = [...vacationTimes];
                          newVacationTimes[index] = text.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          setVacationTimes(newVacationTimes);
                        }}
                        editable={canSubmit}
                        keyboardType="numeric"
                      />
                    </View>
                    <View style={styles.specialHoursWrapper}>
                      <Text
                        style={[
                          styles.inputLabel,
                          colorScheme === "dark" && styles.inputLabelDark,
                        ]}
                      >
                        Sick
                      </Text>
                      <TextInput
                        style={[
                          styles.specialHoursInput,
                          colorScheme === "dark" &&
                            styles.specialHoursInputDark,
                        ]}
                        placeholder="0"
                        placeholderTextColor={
                          colorScheme === "dark"
                            ? "rgba(255, 255, 255, 0.5)"
                            : "rgba(0, 0, 0, 0.5)"
                        }
                        value={sickTimes[index] || ""}
                        onChangeText={(text) => {
                          const newSickTimes = [...sickTimes];
                          newSickTimes[index] = text.replace(/[^0-9.]/g, "");
                          setSickTimes(newSickTimes);
                        }}
                        editable={canSubmit}
                        keyboardType="numeric"
                      />
                    </View>
                    <View style={styles.specialHoursWrapper}>
                      <Text
                        style={[
                          styles.inputLabel,
                          colorScheme === "dark" && styles.inputLabelDark,
                        ]}
                      >
                        Holiday
                      </Text>
                      <TextInput
                        style={[
                          styles.specialHoursInput,
                          colorScheme === "dark" &&
                            styles.specialHoursInputDark,
                        ]}
                        placeholder="0"
                        placeholderTextColor={
                          colorScheme === "dark"
                            ? "rgba(255, 255, 255, 0.5)"
                            : "rgba(0, 0, 0, 0.5)"
                        }
                        value={holidayTimes[index] || ""}
                        onChangeText={(text) => {
                          const newHolidayTimes = [...holidayTimes];
                          newHolidayTimes[index] = text.replace(/[^0-9.]/g, "");
                          setHolidayTimes(newHolidayTimes);
                        }}
                        editable={canSubmit}
                        keyboardType="numeric"
                      />
                    </View>
                  </View>
                  <Text
                    style={[
                      styles.totalHoursText,
                      colorScheme === "dark" && styles.totalHoursTextDark,
                    ]}
                  >
                    Total Hours: {totalWorkHours[index]?.toFixed(2)}
                  </Text>
                  <TouchableOpacity
                    style={[
                      styles.saveButton,
                      (!canSubmit || isSubmitted) && styles.disabledButton,
                      colorScheme === "dark" && styles.saveButtonDark,
                      (!canSubmit || isSubmitted) &&
                        colorScheme === "dark" &&
                        styles.disabledButtonDark,
                    ]}
                    onPress={() => handleSaveDay(index)}
                    disabled={!canSubmit || isSubmitted}
                  >
                    <Text
                      style={[
                        styles.buttonText,
                        (!canSubmit || isSubmitted) &&
                          styles.disabledButtonText,
                        colorScheme === "dark" &&
                          (!canSubmit || isSubmitted) &&
                          styles.disabledButtonTextDark,
                      ]}
                    >
                      {savedDays[index] ? "Update Day" : "Save Day"}
                    </Text>
                  </TouchableOpacity>
                </View>
              ))
            )}

            <View
              style={[
                styles.summaryCard,
                colorScheme === "dark" && styles.summaryCardDark,
              ]}
            >
              <Text
                style={[
                  styles.summaryText,
                  colorScheme === "dark" && styles.summaryTextDark,
                ]}
              >
                Weekly Total Hours:{" "}
                {isSpecialFormatEmployee
                  ? (specialFormatWeeklyTotal ?? 0).toFixed(2)
                  : (weeklyTotalHours ?? 0).toFixed(2)}
              </Text>
              {isSubmitted ? (
                <View style={styles.submissionStatusContainer}>
                  <Text style={styles.submissionStatusText}>
                    Timesheet submitted for this week
                  </Text>
                </View>
              ) : (
                <View style={styles.submissionStatusContainer}>
                  <Text style={styles.submissionStatusText}>
                    {savedDays.filter(Boolean).length} / 7 days saved
                  </Text>
                </View>
              )}
              <View style={styles.signatureContainer}>
                <TouchableOpacity
                  style={styles.checkbox}
                  onPress={() => setIsSignatureChecked(!isSignatureChecked)}
                >
                  {isSignatureChecked && (
                    <Feather name="check" size={20} color="#4CAF50" />
                  )}
                </TouchableOpacity>
                <Text
                  style={[
                    styles.signatureText,
                    colorScheme === "dark" && styles.signatureTextDark,
                  ]}
                >
                  By signing this timesheet, I certify that I have carefully
                  reviewed this timesheet and that the hours reported on this
                  timesheet, including all start and stop times, are accurate. I
                  acknowledge that my electronic signature is legally binding.
                </Text>
              </View>
              <View style={styles.signatureContainer}>
                <TouchableOpacity
                  style={styles.checkbox}
                  onPress={handleTermsCheckboxPress}
                >
                  {hasAgreedToTerms && (
                    <Feather name="check" size={20} color="#4CAF50" />
                  )}
                </TouchableOpacity>
                <Text
                  style={[
                    styles.signatureText,
                    colorScheme === "dark" && styles.signatureTextDark,
                  ]}
                >
                  I agree to the{" "}
                  <Text
                    style={styles.termsLink}
                    onPress={() => setIsTermsModalVisible(true)}
                  >
                    Terms and Conditions
                  </Text>
                  .
                </Text>
              </View>

              <TouchableOpacity
                style={[
                  styles.submitButton,
                  (!isSignatureChecked ||
                    !hasAgreedToTerms ||
                    !canSubmit ||
                    isSubmitted ||
                    isSubmitting) &&
                    styles.disabledButton,
                  colorScheme === "dark" && styles.submitButtonDark,
                  (!isSignatureChecked ||
                    !hasAgreedToTerms ||
                    !canSubmit ||
                    isSubmitted ||
                    isSubmitting) &&
                    colorScheme === "dark" &&
                    styles.disabledButtonDark,
                ]}
                onPress={handleAttendanceSubmit}
                disabled={
                  !isSignatureChecked ||
                  !hasAgreedToTerms ||
                  !canSubmit ||
                  isSubmitted ||
                  isSubmitting
                }
              >
                <Text
                  style={[
                    styles.buttonText,
                    (!isSignatureChecked ||
                      !hasAgreedToTerms ||
                      !canSubmit ||
                      isSubmitted ||
                      isSubmitting) &&
                      styles.disabledButtonText,
                    colorScheme === "dark" &&
                      (!isSignatureChecked ||
                        !hasAgreedToTerms ||
                        !canSubmit ||
                        isSubmitted ||
                        isSubmitting) &&
                      styles.disabledButtonTextDark,
                  ]}
                >
                  {isSubmitting
                    ? "Submitting..."
                    : isSubmitted
                    ? "Timesheet Submitted"
                    : "Submit Timesheet"}
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.actionButtonsContainer}>
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() =>
                  navigation.navigate("Attendance History", { id: params?.id })
                }
              >
                <Text style={styles.actionButtonText}>
                  View Attendance History
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() =>
                  navigation.navigate("Personal Leave", {
                    employeeName: params?.name,
                    employeeId: params?.id,
                    department: params?.department,
                  })
                }
              >
                <Text style={styles.actionButtonText}>
                  Request Personal Leave
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
      <CustomAlert
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
        onConfirm={alertConfirm}
        showCancel={showCancel}
      />

      <Modal
        visible={isSignatureModalVisible}
        animationType="slide"
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.signaturePadContainer}>
            <Text style={styles.modalTitle}>Please sign below</Text>
            <SignatureCanvas
              ref={signaturePadRef}
              onEnd={handleSignatureEnd}
              canvasProps={{
                width: 500,
                height: 200,
                className: "signatureCanvas",
              }}
              webStyle={`
                .m-signature-pad {
                  width: 100%;
                  height: 300px;
                  margin-bottom: 20px;
                }
                .m-signature-pad--body {
                  border: 1px solid #e8e8e8;
                }
                .m-signature-pad--body canvas {
                  width: 100% !important;
                  height: 100% !important;
                }
              `}
            />
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  isSignaturePadEmpty && styles.modalButtonDisabled,
                ]}
                onPress={handleSignatureConfirm}
                disabled={isSignaturePadEmpty}
              >
                <Text style={styles.modalButtonText}>Confirm Signature</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={handleSignatureCancel}
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        visible={isTermsModalVisible}
        animationType="slide"
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.termsModalContent}>
            <Text style={styles.modalTitle}>Terms and Conditions</Text>
            <ScrollView
              style={styles.termsScrollView}
              ref={scrollViewRef}
              onScroll={handleTermsScroll}
              scrollEventThrottle={16}
            >
              <Text style={styles.termsText}>
                {"Terms and Conditions\n"}
                {"====================\n\n"}

                {"Last updated: August 26, 2024\n\n"}

                {
                  "Please read these terms and conditions carefully before using Our Service.\n\n"
                }

                {"Interpretation and Definitions\n"}
                {"------------------------------\n\n"}

                {"Interpretation\n"}
                {"~~~~~~~~~~~~~~\n"}
                {
                  "The words of which the initial letter is capitalized have meanings defined\n"
                }
                {
                  "under the following conditions. The following definitions shall have the same\n"
                }
                {
                  "meaning regardless of whether they appear in singular or in plural.\n\n"
                }

                {"Definitions\n"}
                {"~~~~~~~~~~~\n\n"}

                {"For the purposes of these Terms and Conditions:\n\n"}

                {
                  "\u2022 Affiliate means an entity that controls, is controlled by or is under\n"
                }
                {
                  "common control with a party, where control means ownership of 50% or\n"
                }
                {
                  "more of the shares, equity interest or other securities entitled to vote\n"
                }
                {"for election of directors or other managing authority.\n\n"}

                {
                  "\u2022 Account means a unique account created for You to access our Service or\n"
                }
                {"parts of our Service.\n\n"}

                {"\u2022 Country refers to: Arizona, United States\n\n"}

                {
                  "\u2022 Company (referred to as either the Company, We, Us or Our in this\n"
                }
                {"Agreement) refers to https://sbg-attendance.com/.\n\n"}

                {
                  "\u2022 Device means any device that can access the Service such as a computer, a\n"
                }
                {"cellphone or a digital tablet.\n\n"}

                {"\u2022 Service refers to the Website.\n\n"}

                {
                  "\u2022 Terms and Conditions (also referred as Terms) mean these Terms and\n"
                }
                {
                  "Conditions that form the entire agreement between You and the Company\n"
                }
                {"regarding the use of the Service.\n\n"}

                {
                  "\u2022 Third-party Social Media Service means any services or content (including\n"
                }
                {
                  "data, information, products or services) provided by a third-party that\n"
                }
                {
                  "may be displayed, included or made available by the Service.\n\n"
                }

                {
                  "\u2022 Website refers to https://sbg-attendance.com/, accessible from\n"
                }
                {"<https://sbg-attendance.com/>\n\n"}

                {
                  "\u2022 You means the individual accessing or using the Service, or the company,\n"
                }
                {
                  "or other legal entity on behalf of which such individual is accessing or\n"
                }
                {"using the Service, as applicable.\n\n"}

                {"Acknowledgment\n"}
                {"--------------\n"}
                {
                  "These are the Terms and Conditions governing the use of this Service and the\n"
                }
                {
                  "agreement that operates between You and the Company. These Terms and\n"
                }
                {
                  "Conditions set out the rights and obligations of all users regarding the use\n"
                }
                {"of the Service.\n\n"}

                {
                  "Your access to and use of the Service is conditioned on Your acceptance of and\n"
                }
                {
                  "compliance with these Terms and Conditions. These Terms and Conditions apply\n"
                }
                {
                  "to all visitors, users and others who access or use the Service.\n\n"
                }

                {
                  "By accessing or using the Service You agree to be bound by these Terms and\n"
                }
                {
                  "Conditions. If You disagree with any part of these Terms and Conditions then\n"
                }
                {"You may not access the Service.\n\n"}

                {
                  "You represent that you are over the age of 18. The Company does not permit\n"
                }
                {"those under 18 to use the Service.\n\n"}

                {
                  "Your access to and use of the Service is also conditioned on Your acceptance\n"
                }
                {
                  "of and compliance with the Privacy Policy of the Company. Our Privacy Policy\n"
                }
                {
                  "describes Our policies and procedures on the collection, use and disclosure of\n"
                }
                {
                  "Your personal information when You use the Application or the Website and\n"
                }
                {
                  "tells You about Your privacy rights and how the law protects You. Please read\n"
                }
                {"Our Privacy Policy carefully before using Our Service.\n\n"}

                {"User Accounts\n"}
                {"-------------\n"}
                {
                  "When You create an account with Us, You must provide Us information that is\n"
                }
                {
                  "accurate, complete, and current at all times. Failure to do so constitutes a\n"
                }
                {
                  "breach of the Terms, which may result in immediate termination of Your account\n"
                }
                {"on Our Service.\n\n"}

                {
                  "You are responsible for safeguarding the password that You use to access the\n"
                }
                {
                  "Service and for any activities or actions under Your password, whether Your\n"
                }
                {
                  "password is with Our Service or a Third-Party Social Media Service.\n\n"
                }

                {
                  "You agree not to disclose Your password to any third party. You must notify Us\n"
                }
                {
                  "immediately upon becoming aware of any breach of security or unauthorized use\n"
                }
                {"of Your account.\n\n"}

                {
                  "You may not use as a username the name of another person or entity or that is\n"
                }
                {
                  "not lawfully available for use, a name or trademark that is subject to any\n"
                }
                {
                  "rights of another person or entity other than You without appropriate\n"
                }
                {
                  "authorization, or a name that is otherwise offensive, vulgar or obscene.\n\n"
                }

                {"Intellectual Property\n"}
                {"---------------------\n"}
                {
                  "The Service and its original content (excluding Content provided by You or\n"
                }
                {
                  "other users), features and functionality are and will remain the exclusive\n"
                }
                {"property of the Company and its licensors.\n\n"}

                {
                  "The Service is protected by copyright, trademark, and other laws of both the\n"
                }
                {"Country and foreign countries.\n\n"}

                {
                  "Our trademarks and trade dress may not be used in connection with any product\n"
                }
                {
                  "or service without the prior written consent of the Company.\n\n"
                }

                {"Links to Other Websites\n"}
                {"-----------------------\n"}
                {
                  "Our Service may contain links to third-party web sites or services that are\n"
                }
                {"not owned or controlled by the Company.\n\n"}

                {
                  "The Company has no control over, and assumes no responsibility for, the\n"
                }
                {
                  "content, privacy policies, or practices of any third party web sites or\n"
                }
                {
                  "services. You further acknowledge and agree that the Company shall not be\n"
                }
                {
                  "responsible or liable, directly or indirectly, for any damage or loss caused\n"
                }
                {
                  "or alleged to be caused by or in connection with the use of or reliance on any\n"
                }
                {
                  "such content, goods or services available on or through any such web sites or\n"
                }
                {"services.\n\n"}

                {
                  "We strongly advise You to read the terms and conditions and privacy policies\n"
                }
                {"of any third-party web sites or services that You visit.\n\n"}

                {"Termination\n"}
                {"-----------\n"}
                {
                  "We may terminate or suspend Your Account immediately, without prior notice or\n"
                }
                {
                  "liability, for any reason whatsoever, including without limitation if You\n"
                }
                {"breach these Terms and Conditions.\n\n"}

                {
                  "Upon termination, Your right to use the Service will cease immediately. If You\n"
                }
                {
                  "wish to terminate Your Account, You may simply discontinue using the Service.\n\n"
                }

                {"Limitation of Liability\n"}
                {"-----------------------\n"}
                {
                  "Notwithstanding any damages that You might incur, the entire liability of the\n"
                }
                {
                  "Company and any of its suppliers under any provision of this Terms and Your\n"
                }
                {
                  "exclusive remedy for all of the foregoing shall be limited to the amount\n"
                }
                {
                  "actually paid by You through the Service or 100 USD if You haven't purchased\n"
                }
                {"anything through the Service.\n\n"}

                {
                  "To the maximum extent permitted by applicable law, in no event shall the\n"
                }
                {
                  "Company or its suppliers be liable for any special, incidental, indirect, or\n"
                }
                {
                  "consequential damages whatsoever (including, but not limited to, damages for\n"
                }
                {
                  "loss of profits, loss of data or other information, for business interruption,\n"
                }
                {
                  "for personal injury, loss of privacy arising out of or in any way related to\n"
                }
                {
                  "the use of or inability to use the Service, third-party software and/or third-\n"
                }
                {
                  "party hardware used with the Service, or otherwise in connection with any\n"
                }
                {
                  "provision of this Terms), even if the Company or any supplier has been advised\n"
                }
                {
                  "of the possibility of such damages and even if the remedy fails of its\n"
                }
                {"essential purpose.\n\n"}

                {
                  "Some states do not allow the exclusion of implied warranties or limitation of\n"
                }
                {
                  "liability for incidental or consequential damages, which means that some of\n"
                }
                {
                  "the above limitations may not apply. In these states, each party's liability\n"
                }
                {"will be limited to the greatest extent permitted by law.\n\n"}

                {'"AS IS" and "AS AVAILABLE" Disclaimer\n'}
                {"-------------------------------------\n"}
                {
                  'The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults\n'
                }
                {
                  "and defects without warranty of any kind. To the maximum extent permitted\n"
                }
                {
                  "under applicable law, the Company, on its own behalf and on behalf of its\n"
                }
                {
                  "Affiliates and its and their respective licensors and service providers,\n"
                }
                {
                  "expressly disclaims all warranties, whether express, implied, statutory or\n"
                }
                {
                  "otherwise, with respect to the Service, including all implied warranties of\n"
                }
                {
                  "merchantability, fitness for a particular purpose, title and non-infringement,\n"
                }
                {
                  "and warranties that may arise out of course of dealing, course of performance,\n"
                }
                {
                  "usage or trade practice. Without limitation to the foregoing, the Company\n"
                }
                {
                  "provides no warranty or undertaking, and makes no representation of any kind\n"
                }
                {
                  "that the Service will meet Your requirements, achieve any intended results, be\n"
                }
                {
                  "compatible or work with any other software, applications, systems or services,\n"
                }
                {
                  "operate without interruption, meet any performance or reliability standards or\n"
                }
                {
                  "be error free or that any errors or defects can or will be corrected.\n\n"
                }

                {
                  "Without limiting the foregoing, neither the Company nor any of the company's\n"
                }
                {
                  "provider makes any representation or warranty of any kind, express or implied:\n"
                }
                {
                  "(i) as to the operation or availability of the Service, or the information,\n"
                }
                {
                  "content, and materials or products included thereon; (ii) that the Service\n"
                }
                {
                  "will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or\n"
                }
                {
                  "currency of any information or content provided through the Service; or (iv)\n"
                }
                {
                  "that the Service, its servers, the content, or e-mails sent from or on behalf\n"
                }
                {
                  "of the Company are free of viruses, scripts, trojan horses, worms, malware,\n"
                }
                {"timebombs or other harmful components.\n\n"}

                {
                  "Some jurisdictions do not allow the exclusion of certain types of warranties\n"
                }
                {
                  "or limitations on applicable statutory rights of a consumer, so some or all of\n"
                }
                {
                  "the above exclusions and limitations may not apply to You. But in such a case\n"
                }
                {
                  "the exclusions and limitations set forth in this section shall be applied to\n"
                }
                {"the greatest extent enforceable under applicable law.\n\n"}

                {"Governing Law\n"}
                {"-------------\n"}
                {
                  "The laws of the Country, excluding its conflicts of law rules, shall govern\n"
                }
                {
                  "this Terms and Your use of the Service. Your use of the Application may also\n"
                }
                {
                  "be subject to other local, state, national, or international laws.\n\n"
                }

                {"Disputes Resolution\n"}
                {"-------------------\n"}
                {
                  "If You have any concern or dispute about the Service, You agree to first try\n"
                }
                {
                  "to resolve the dispute informally by contacting the Company.\n\n"
                }

                {"For European Union (EU) Users\n"}
                {"-----------------------------\n"}
                {
                  "If You are a European Union consumer, you will benefit from any mandatory\n"
                }
                {
                  "provisions of the law of the country in which You are resident.\n\n"
                }

                {"United States Legal Compliance\n"}
                {"------------------------------\n"}
                {
                  "You represent and warrant that (i) You are not located in a country that is\n"
                }
                {
                  "subject to the United States government embargo, or that has been designated\n"
                }
                {
                  'by the United States government as a "terrorist supporting" country, and (ii)\n'
                }
                {
                  "You are not listed on any United States government list of prohibited or\n"
                }
                {"restricted parties.\n\n"}

                {"Severability and Waiver\n"}
                {"-----------------------\n"}

                {"Severability\n"}
                {"~~~~~~~~~~~~\n"}
                {
                  "If any provision of these Terms is held to be unenforceable or invalid, such\n"
                }
                {
                  "provision will be changed and interpreted to accomplish the objectives of such\n"
                }
                {
                  "provision to the greatest extent possible under applicable law and the\n"
                }
                {
                  "remaining provisions will continue in full force and effect.\n\n"
                }

                {"Waiver\n"}
                {"~~~~~~\n"}
                {
                  "Except as provided herein, the failure to exercise a right or to require\n"
                }
                {
                  "performance of an obligation under these Terms shall not affect a party's\n"
                }
                {
                  "ability to exercise such right or require such performance at any time\n"
                }
                {
                  "thereafter nor shall the waiver of a breach constitute a waiver of any\n"
                }
                {"subsequent breach.\n\n"}

                {"Translation Interpretation\n"}
                {"--------------------------\n"}
                {
                  "These Terms and Conditions may have been translated if We have made them\n"
                }
                {
                  "available to You on our Service. You agree that the original English text\n"
                }
                {"shall prevail in the case of a dispute.\n\n"}

                {"Changes to These Terms and Conditions\n"}
                {"-------------------------------------\n"}
                {
                  "We reserve the right, at Our sole discretion, to modify or replace these Terms\n"
                }
                {
                  "at any time. If a revision is material We will make reasonable efforts to\n"
                }
                {
                  "provide at least 30 days' notice prior to any new terms taking effect. What\n"
                }
                {
                  "constitutes a material change will be determined at Our sole discretion.\n\n"
                }

                {
                  "By continuing to access or use Our Service after those revisions become\n"
                }
                {
                  "effective, You agree to be bound by the revised terms. If You do not agree to\n"
                }
                {
                  "the new terms, in whole or in part, please stop using the website and the\n"
                }
                {"Service.\n\n"}

                {"Contact Us\n"}
                {"----------\n"}
                {
                  "If you have any questions about these Terms and Conditions, You can contact\n"
                }
                {"us:\n\n"}

                {"   By email: sbg.software.dev@gmail.com\n"}
              </Text>
            </ScrollView>
            <View style={styles.modalButtonContainer}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  !hasScrolledToBottom && styles.disabledButton,
                ]}
                onPress={handleAgreeToTerms}
                disabled={!hasScrolledToBottom}
              >
                <Text style={styles.modalButtonText}>
                  {hasScrolledToBottom
                    ? "I Agree"
                    : "Please scroll to the bottom"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={() => setIsTermsModalVisible(false)}
              >
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F8F9FA",
  },
  containerDark: {
    backgroundColor: "#121212",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
  headerTextDark: {
    color: "#FFFFFF",
  },
  backButton: {
    padding: 8,
  },
  themeToggle: {
    padding: 8,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 16,
  },
  desktopContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1200,
    marginHorizontal: "auto",
  },
  column: {
    flex: 1,
  },
  leftColumn: {
    marginRight: 20,
    flex: 2,
  },
  rightColumn: {
    flex: 1,
    marginTop: 20,
  },
  welcomeContainer: {
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  welcomeContainerDark: {
    backgroundColor: "#1E1E1E",
  },
  welcomeText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  welcomeTextDark: {
    color: "#FFFFFF",
  },
  dateRangeText: {
    fontSize: 14,
    color: "#666",
  },
  dateRangeTextDark: {
    color: "#CCCCCC",
  },
  timesheetMessage: {
    fontSize: 14,
    color: "#dc3545",
    marginTop: 10,
  },
  infoCard: {
    backgroundColor: "#E3F2FD",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    flexDirection: "row",
  },
  infoCardDark: {
    backgroundColor: "#1E1E1E",
  },
  infoIcon: {
    marginRight: 10,
    marginTop: 2,
  },
  infoTextContainer: {
    flex: 1,
  },
  infoTextHeader: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  infoTextHeaderDark: {
    color: "#FFFFFF",
  },
  infoText: {
    fontSize: 14,
    color: "#333",
    marginBottom: 5,
  },
  infoTextDark: {
    color: "#CCCCCC",
  },
  boldText: {
    fontWeight: "bold",
  },
  dayCard: {
    backgroundColor: "#fff",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  dayCardDark: {
    backgroundColor: "#1E1E1E",
  },
  currentDayCard: {
    borderColor: "#007AFF",
    borderWidth: 2,
  },
  dayText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  dayTextDark: {
    color: "#FFFFFF",
  },
  timeInputsRow: {
    flexDirection: "column",
    marginBottom: 10,
  },
  timeInputWrapper: {
    marginBottom: 10,
  },
  timeInputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  inputLabel: {
    fontSize: 12,
    color: "#666",
    marginBottom: 5,
  },
  inputLabelDark: {
    color: "#CCCCCC",
  },
  timeInput: {
    flex: 2,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    height: 40,
  },
  timeInputDark: {
    borderColor: "#333",
    color: "#FFFFFF",
  },
  pickerContainer: {
    flex: 1,
    height: 40,
    marginLeft: 5,
    justifyContent: "center",
  },
  amPmPicker: {
    height: 40,
  },
  specialHoursContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  specialHoursWrapper: {
    width: "30%",
    marginBottom: 10,
  },
  specialHoursInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    height: 40,
  },
  specialHoursInputDark: {
    borderColor: "#333",
    color: "#FFFFFF",
  },
  totalHoursText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginTop: 10,
  },
  totalHoursTextDark: {
    color: "#FFFFFF",
  },
  saveButton: {
    backgroundColor: "#28a745",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  saveButtonDark: {
    backgroundColor: "#1E6E34",
  },
  updateButton: {
    backgroundColor: "#007AFF",
  },
  updateButtonDark: {
    backgroundColor: "#0056b3",
  },
  disabledButton: {
    backgroundColor: "#cccccc",
  },
  disabledButtonDark: {
    backgroundColor: "#444444",
  },
  buttonText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  disabledButtonText: {
    color: "#666666",
  },
  disabledButtonTextDark: {
    color: "#999999",
  },
  summaryCard: {
    backgroundColor: "#fff",
    padding: 20,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  summaryCardDark: {
    backgroundColor: "#1E1E1E",
  },
  summaryText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 15,
  },
  summaryTextDark: {
    color: "#FFFFFF",
  },
  submissionStatusContainer: {
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  submissionStatusText: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "#333",
  },
  signatureContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: "#4CAF50",
    borderRadius: 4,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  signatureText: {
    flex: 1,
    fontSize: 14,
    color: "#333",
  },
  signatureTextDark: {
    color: "#FFFFFF",
  },
  submitButton: {
    backgroundColor: "#007AFF",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  submitButtonDark: {
    backgroundColor: "#0056b3",
  },
  actionButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  actionButton: {
    backgroundColor: "#6c757d",
    padding: 15,
    borderRadius: 5,
    flex: 1,
    marginHorizontal: 5,
    alignItems: "center",
  },
  actionButtonDark: {
    backgroundColor: "#333",
  },
  actionButtonText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 15,
  },
  sectionHeaderDark: {
    color: "#FFFFFF",
  },
  historyCard: {
    backgroundColor: "#fff",
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  historyCardDark: {
    backgroundColor: "#2C2C2C",
    shadowColor: "#FFF",
  },
  currentWeekCard: {
    borderColor: "#4CAF50",
    borderWidth: 2,
  },
  historyWeekOf: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 10,
  },
  historyWeekOfDark: {
    color: "#FFFFFF",
  },
  historyDay: {
    marginBottom: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  historyDayDark: {
    borderBottomColor: "#444",
  },
  historyDate: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
  },
  historyDateDark: {
    color: "#FFFFFF",
  },
  historyHours: {
    fontSize: 14,
    color: "#666",
  },
  historyHoursDark: {
    color: "#CCCCCC",
  },
  historyTotalHours: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginTop: 10,
  },
  historyTotalHoursDark: {
    color: "#4CAF50",
  },
  noDataText: {
    color: "#666",
    fontStyle: "italic",
  },
  noDataTextDark: {
    color: "#AAAAAA",
  },
  historyWeek: {
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#007AFF",
    borderRadius: 10,
    padding: 10,
  },
  historyWeekTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#007AFF",
    marginBottom: 10,
  },
  historyWeekTitleDark: {
    color: "#0A84FF",
  },
  historyDayDetails: {
    marginLeft: 10,
  },
  historyServiceText: {
    fontSize: 14,
    color: "#666",
  },
  historyServiceTextDark: {
    color: "#CCCCCC",
  },
  historyWeeklyTotal: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#007AFF",
    marginTop: 10,
    textAlign: "right",
  },
  historyWeeklyTotalDark: {
    color: "#0A84FF",
  },
  errorContainer: {
    padding: 10,
    backgroundColor: "#FFEBEE",
    borderRadius: 5,
  },
  errorText: {
    color: "#D32F2F",
  },
  errorTextDark: {
    color: "#FF8A80",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  signaturePadContainer: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    width: "95%",
    maxWidth: 600,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center",
  },
  modalButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  modalButton: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginHorizontal: 5,
    backgroundColor: "#007AFF",
  },
  modalButtonDisabled: {
    backgroundColor: "#cccccc",
  },
  cancelButton: {
    backgroundColor: "#f0f0f0",
  },
  modalButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
  },
  cancelButtonText: {
    color: "#333",
    fontSize: 16,
  },
  termsLink: {
    color: "#007AFF",
    textDecorationLine: "underline",
  },
  termsModalContent: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    borderRadius: 10,
    width: "95%",
    maxWidth: 600,
    maxHeight: "80%",
  },
  termsScrollView: {
    maxHeight: 400,
  },
  termsText: {
    fontSize: 14,
    color: "#333333",
    marginBottom: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  loadingText: {
    fontSize: 18,
    color: "#333",
  },
  //

  // Styles for special format timesheets
  standbyToggle: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  standbyText: {
    marginLeft: 8,
    fontSize: 16,
    color: "#333",
  },
  standbyTextDark: {
    color: "#FFFFFF",
  },
  serviceInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 8,
    fontSize: 14,
    marginBottom: 10,
  },
  serviceInputDark: {
    borderColor: "#555",
    color: "#FFFFFF",
    backgroundColor: "#333",
  },
  addServiceButton: {
    backgroundColor: "#007AFF",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  addServiceButtonDark: {
    backgroundColor: "#0A84FF",
  },
  removeServiceButton: {
    backgroundColor: "#dc3545",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 5,
  },
  removeServiceButtonDark: {
    backgroundColor: "#FF453A",
  },
  serviceContainer: {
    marginBottom: 15,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    padding: 10,
  },
  serviceContainerDark: {
    borderColor: "#555",
    backgroundColor: "#2C2C2C",
  },
  serviceTotalText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#333",
    marginTop: 5,
  },
  serviceTotalTextDark: {
    color: "#FFFFFF",
  },
  lunchBreakContainer: {
    marginTop: 10,
  },
  lunchBreakText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 5,
  },
  lunchBreakTextDark: {
    color: "#FFFFFF",
  },
  // Styles for the period toggle in recent history
  periodToggleContainer: {
    flexDirection: "row",
    marginLeft: 8,
  },
  periodButton: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    borderRadius: 5,
    marginLeft: 4,
    backgroundColor: "#FFFFFF",
  },
  periodButtonDark: {
    borderColor: "#555555",
    backgroundColor: "#3C3C3C",
  },
  selectedPeriodButton: {
    backgroundColor: "#007AFF",
    borderColor: "#007AFF",
  },
  selectedPeriodButtonDark: {
    backgroundColor: "#0A84FF",
    borderColor: "#0A84FF",
  },
  periodButtonText: {
    fontSize: 14,
    color: "#000000",
  },
  periodButtonTextDark: {
    color: "#FFFFFF",
  },
  selectedPeriodButtonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  selectedPeriodButtonTextDark: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  disabledPeriodButton: {
    backgroundColor: "#F0F0F0",
    borderColor: "#A9A9A9",
  },
  disabledPeriodButtonText: {
    color: "#A9A9A9",
  },
  // Additional styles for better layout and responsiveness
  desktopTableScrollView: {
    width: "70%",
  },
  expandableTimeDetails: {
    padding: 5,
  },
  expandableTimeTotal: {
    fontSize: 14,
    color: "#333",
  },
  expandableTimeTotalDark: {
    color: "#FFFFFF",
  },
  expandableTimeBreakdown: {
    fontSize: 12,
    color: "#666",
    marginTop: 2,
  },
  expandableTimeBreakdownDark: {
    color: "#CCCCCC",
  },
  // Navigation Styles for recent history
  navigationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  navButton: {
    padding: 10,
  },
  navButtonDisabled: {
    opacity: 0.5,
  },
  periodIndicator: {
    marginHorizontal: 20,
    fontSize: 16,
    color: "#333",
  },
  periodIndicatorDark: {
    color: "#FFFFFF",
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
});

export default User;
