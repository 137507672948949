import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const TimeIcon = ({ time, size = 80, isDarkMode = false }) => {
  const getIconContent = () => {
    switch (time) {
      case "morning":
        return (
          <>
            <Circle
              cx={size / 2}
              cy={size / 2}
              r={size / 3}
              fill={isDarkMode ? "#FFD700" : "#FDB813"}
            />
            {/* Sun rays */}
            {[...Array(8)].map((_, i) => (
              <Path
                key={i}
                d={`M ${size / 2} ${size / 6} L ${size / 2} ${size / 12}`}
                stroke={isDarkMode ? "#FFD700" : "#FDB813"}
                strokeWidth="2"
                transform={`rotate(${i * 45} ${size / 2} ${size / 2})`}
              />
            ))}
          </>
        );

      case "afternoon":
        return (
          <>
            <Circle
              cx={size / 2}
              cy={size / 2}
              r={size / 3}
              fill={isDarkMode ? "#FFA500" : "#FF8C00"}
            />
            <Path
              d={`M ${size / 4} ${(size * 3) / 4} Q ${size / 2} ${
                (size * 7) / 8
              } ${(size * 3) / 4} ${(size * 3) / 4}`}
              fill="none"
              stroke={isDarkMode ? "#FFA500" : "#FF8C00"}
              strokeWidth="2"
            />
          </>
        );

      case "evening":
        return (
          <>
            <Path
              d={`M ${size / 3} ${size / 2} 
                  a ${size / 3} ${size / 3} 0 0 1 ${size / 3} ${-size / 6}
                  a ${size / 4} ${size / 4} 0 0 0 ${size / 3} ${size / 6}`}
              fill={isDarkMode ? "#C0C0C0" : "#7986CB"}
            />
            {[...Array(3)].map((_, i) => (
              <Circle
                key={i}
                cx={size / 2 + ((i - 1) * size) / 6}
                cy={size / 3}
                r={2}
                fill={isDarkMode ? "#FFFFFF" : "#FFEB3B"}
              />
            ))}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {getIconContent()}
    </Svg>
  );
};

export default TimeIcon;
