import React, { useState, useEffect, useMemo } from "react";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  ActivityIndicator,
  Pressable,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  useWindowDimensions,
} from "react-native";
import axios from "axios";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Feather } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment-timezone";
import CustomLoading from "./CustomLoading";
import { signOut } from "firebase/auth";

const BackButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color="#007AFF" />
    </TouchableOpacity>
  );
};

const SupervisorApproval = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [payPeriod, setPayPeriod] = useState("");
  const [isBeforeNoonTuesday, setIsBeforeNoonTuesday] = useState(true);
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  const getCurrentPayPeriod = () => {
    const now = moment().tz("America/Phoenix");
    const thisSunday = now.clone().startOf("week");
    const tuesdayNoon = thisSunday
      .clone()
      .add(2, "days")
      .hour(12)
      .minute(0)
      .second(0);

    let payPeriodStart;
    let payPeriodEnd;
    let beforeNoon = true;

    if (now.isBefore(tuesdayNoon)) {
      payPeriodStart = thisSunday.clone().subtract(1, "week");
      payPeriodEnd = payPeriodStart.clone().endOf("week");
      beforeNoon = true;
    } else {
      payPeriodStart = thisSunday.clone();
      payPeriodEnd = payPeriodStart.clone().endOf("week");
      beforeNoon = false;
    }

    return { payPeriodStart, payPeriodEnd, beforeNoon };
  };

  useFocusEffect(
    React.useCallback(() => {
      const fetchEmployees = async () => {
        try {
          setLoading(true);

          const currentUser = FIREBASE_AUTH.currentUser;
          if (!currentUser) {
            console.log("No user logged in");
            return;
          }

          const db = getFirestore();
          const docRef = doc(db, "users", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const isAdmin = userData.role === "admin";
            const isSupervisor = userData.role === "supervisor";
            const supervisorName = userData.name;

            let allEmployeesResponse;
            if (isAdmin) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees"
              );
            } else if (isSupervisor) {
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                { params: { supervisorName: supervisorName } }
              );
            } else {
              const userEmail = currentUser.email;
              allEmployeesResponse = await axios.get(
                "https://sbg-backend.onrender.com/employees",
                { params: { email: userEmail } }
              );
            }

            const { payPeriodStart, payPeriodEnd, beforeNoon } =
              getCurrentPayPeriod();

            setPayPeriod(
              `${payPeriodStart.format("MMMM D, YYYY")} - ${payPeriodEnd.format(
                "MMMM D, YYYY"
              )}`
            );
            setIsBeforeNoonTuesday(beforeNoon);

            const employeesWithStatus = await Promise.all(
              allEmployeesResponse.data.map(async (employee) => {
                const attendanceResponse = await axios.get(
                  `https://sbg-backend.onrender.com/attendances/${employee.employeeId}`
                );

                const currentPeriodAttendance = attendanceResponse.data.find(
                  (attendance) => {
                    const attendanceWeekStart = moment.tz(
                      attendance.weekStart,
                      "America/Phoenix"
                    );
                    return attendanceWeekStart.isSame(payPeriodStart, "day");
                  }
                );

                const submissionStatus = currentPeriodAttendance
                  ? currentPeriodAttendance.isSubmitted
                  : false;
                const approvedStatus = currentPeriodAttendance
                  ? currentPeriodAttendance.approved
                  : false;

                const leaveResponse = await axios.get(
                  `https://sbg-backend.onrender.com/personal-leave/${employee.employeeId}`
                );
                const hasPendingLeaveRequest = leaveResponse.data.some(
                  (leave) => leave.status === "Pending"
                );

                return {
                  ...employee,
                  submitted: submissionStatus,
                  approved: approvedStatus,
                  hasPendingLeaveRequest,
                };
              })
            );

            const sortedEmployees = employeesWithStatus.sort((a, b) =>
              a.employeeName.localeCompare(b.employeeName)
            );

            setEmployees(sortedEmployees);
            setFilteredEmployees(sortedEmployees);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching employees:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchEmployees();
    }, [])
  );

  useEffect(() => {
    const filtered = employees
      .filter((employee) =>
        employee.employeeName.toLowerCase().includes(searchInput.toLowerCase())
      )
      .sort((a, b) => a.employeeName.localeCompare(b.employeeName));
    setFilteredEmployees(filtered);
  }, [searchInput, employees]);

  const handleLogout = async () => {
    try {
      await signOut(FIREBASE_AUTH);
      navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView
      style={[styles.container, colorScheme === "dark" && styles.containerDark]}
    >
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />

      {/* Header Section */}
      <View
        style={[
          styles.header,
          colorScheme === "dark" && styles.headerDark,
          isDesktop && styles.desktopHeader,
        ]}
      >
        <BackButton />
        <Text
          style={[styles.title, colorScheme === "dark" && styles.titleDark]}
        >
          Supervisor Approval
        </Text>
        <View style={styles.headerRightContainer}>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={isDesktop ? 24 : 20}
              color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
            <Feather
              name="log-out"
              size={24}
              color={colorScheme === "dark" ? "#FFFFFF" : "#007AFF"}
            />
          </TouchableOpacity>
        </View>
      </View>

      {/* Stats Section */}
      <View style={styles.statsContainer}>
        <View
          style={[
            styles.statsCard,
            colorScheme === "dark" && styles.statsCardDark,
          ]}
        >
          <Text
            style={[
              styles.statsLabel,
              colorScheme === "dark" && styles.statsLabelDark,
            ]}
          >
            Pay Period
          </Text>
          <Text
            style={[
              styles.statsValue,
              colorScheme === "dark" && styles.statsValueDark,
            ]}
          >
            {payPeriod}
          </Text>
        </View>

        <View
          style={[
            styles.statsCard,
            colorScheme === "dark" && styles.statsCardDark,
          ]}
        >
          <Text
            style={[
              styles.statsLabel,
              colorScheme === "dark" && styles.statsLabelDark,
            ]}
          >
            Pending Approvals
          </Text>
          <Text
            style={[
              styles.statsValue,
              colorScheme === "dark" && styles.statsValueDark,
            ]}
          >
            {
              filteredEmployees.filter((emp) => emp.submitted && !emp.approved)
                .length
            }
          </Text>
        </View>

        <View
          style={[
            styles.statsCard,
            colorScheme === "dark" && styles.statsCardDark,
          ]}
        >
          <Text
            style={[
              styles.statsLabel,
              colorScheme === "dark" && styles.statsLabelDark,
            ]}
          >
            Leave Requests
          </Text>
          <Text
            style={[
              styles.statsValue,
              colorScheme === "dark" && styles.statsValueDark,
            ]}
          >
            {
              filteredEmployees.filter((emp) => emp.hasPendingLeaveRequest)
                .length
            }
          </Text>
        </View>
      </View>

      <Text
        style={[
          styles.periodNote,
          colorScheme === "dark" && styles.periodNoteDark,
        ]}
      >
        {isBeforeNoonTuesday
          ? "Managing submissions for the previous pay period. Pay period will switch after Tuesday at noon."
          : "Managing submissions for the current pay period. Pay period switched after Tuesday at noon."}{" "}
        To view full submission history, click on an employee's name.
      </Text>

      {/* Search and Filter Section */}
      <View
        style={[
          styles.searchContainer,
          colorScheme === "dark" && styles.searchContainerDark,
          isDesktop && styles.desktopSearchContainer,
        ]}
      >
        <Feather
          name="search"
          size={20}
          color="#8E8E93"
          style={styles.searchIcon}
        />
        <TextInput
          style={[
            styles.searchInput,
            colorScheme === "dark" && styles.searchInputDark,
          ]}
          placeholder="Search by name"
          placeholderTextColor="#8E8E93"
          value={searchInput}
          onChangeText={setSearchInput}
        />
      </View>

      {/* Employee List */}
      <ScrollView style={styles.scrollContent}>
        <View style={styles.employeeList}>
          {filteredEmployees.map((item) => (
            <Pressable
              key={item._id}
              onPress={() =>
                navigation.navigate("Timeesheets/Personal Leave", {
                  employeeId: item.employeeId,
                })
              }
              style={({ pressed }) => [
                styles.employeeItem,
                colorScheme === "dark" && styles.employeeItemDark,
                pressed && styles.employeeItemPressed,
              ]}
            >
              {/* Employee Initial Circle */}
              <View style={styles.initialCircle}>
                <Text style={styles.initialText}>
                  {item.employeeName.charAt(0)}
                </Text>
              </View>

              {/* Employee Info */}
              <View style={styles.employeeInfo}>
                <Text
                  style={[
                    styles.employeeName,
                    colorScheme === "dark" && styles.employeeNameDark,
                  ]}
                >
                  {item.employeeName}
                </Text>
                <Text
                  style={[
                    styles.employeeRole,
                    colorScheme === "dark" && styles.employeeRoleDark,
                  ]}
                >
                  {item.jobTitle}, {item.department}
                </Text>
              </View>

              {/* Status Badges */}
              <View style={styles.badgeContainer}>
                {item.approved ? (
                  <View style={[styles.badge, styles.approvedBadge]}>
                    <Feather
                      name="check"
                      size={12}
                      color="white"
                      style={styles.badgeIcon}
                    />
                    <Text style={styles.badgeText}>Approved</Text>
                  </View>
                ) : item.submitted ? (
                  <View style={[styles.badge, styles.submittedBadge]}>
                    <Feather
                      name="clock"
                      size={12}
                      color="white"
                      style={styles.badgeIcon}
                    />
                    <Text style={styles.badgeText}>Submitted</Text>
                  </View>
                ) : (
                  <View style={[styles.badge, styles.pendingBadge]}>
                    <Feather
                      name="alert-circle"
                      size={12}
                      color="white"
                      style={styles.badgeIcon}
                    />
                    <Text style={styles.badgeText}>Not Submitted</Text>
                  </View>
                )}
              </View>
            </Pressable>
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F6F6F6",
  },
  containerDark: {
    backgroundColor: "#000000",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E5E5",
  },
  headerDark: {
    backgroundColor: "#1C1C1E",
    borderBottomColor: "#2C2C2E",
  },
  title: {
    fontSize: 17,
    fontWeight: "600",
    flex: 1,
    textAlign: "center",
    color: "#000000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  searchContainer: {
    margin: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 2,
  },
  searchContainerDark: {
    backgroundColor: "#1C1C1E",
  },
  searchInput: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    color: "#000000",
  },
  searchInputDark: {
    color: "#FFFFFF",
  },
  employeeList: {
    padding: 16,
  },
  employeeItem: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    padding: 12,
    marginBottom: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
  },
  employeeItemDark: {
    backgroundColor: "#1C1C1E",
  },
  employeeItemPressed: {
    opacity: 0.7,
  },
  initialCircle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#007AFF",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
  },
  initialText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "600",
  },
  employeeInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 16,
    fontWeight: "500",
    color: "#000000",
    marginBottom: 4,
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  employeeRole: {
    fontSize: 14,
    color: "#666666",
  },
  employeeRoleDark: {
    color: "#8E8E93",
  },
  badgeContainer: {
    flexDirection: "row",
    gap: 8,
  },
  badge: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    minWidth: 80,
  },
  submittedBadge: {
    backgroundColor: "#007AFF", // Blue for submitted
  },
  pendingBadge: {
    backgroundColor: "#FF3B30", // Red for not submitted
  },
  approvedBadge: {
    backgroundColor: "#34C759", // Green for approved
  },
  badgeIcon: {
    marginRight: 4,
  },
  badgeText: {
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: "500",
  },
  statsContainer: {
    flexDirection: "row",
    padding: 16,
    gap: 12,
  },
  statsCard: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 4,
    elevation: 2,
  },
  statsCardDark: {
    backgroundColor: "#1C1C1E",
  },
  statsLabel: {
    fontSize: 14,
    color: "#666666",
    marginBottom: 4,
  },
  statsLabelDark: {
    color: "#8E8E93",
  },
  statsValue: {
    fontSize: 24,
    fontWeight: "600",
    color: "#007AFF",
  },
  statsValueDark: {
    color: "#0A84FF",
  },
  periodNote: {
    fontSize: 13,
    color: "#666666",
    paddingHorizontal: 16,
    paddingBottom: 16,
    lineHeight: 18,
  },
  periodNoteDark: {
    color: "#8E8E93",
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
  // ... (you can add other dynamic styles here if needed)
});

export default SupervisorApproval;
