import React from "react";
import Svg, { Rect, Text as SvgText } from "react-native-svg";

const Logo = ({ width = 200, height = 50, isDarkMode = false }) => {
  // Professional blue color scheme
  const colors = {
    light: {
      background: "#f0f4f8", // Light blue-gray background
      primary: "#1a73e8", // Google Blue
      secondary: "#174ea6", // Darker blue
      text: "#202124", // Near black for text
      squares: [
        "#1a73e8", // Google Blue
        "#4285f4", // Lighter blue
        "#5094ed", // Mid blue
        "#699df0", // Soft blue
        "#83a7f3", // Lightest blue
      ],
    },
    dark: {
      background: "#1a1c1e", // Dark background
      primary: "#8ab4f8", // Light blue for dark mode
      secondary: "#aecbfa", // Lighter blue for dark mode
      text: "#e8eaed", // Light gray for text
      squares: [
        "#8ab4f8", // Light blue
        "#93bbf9", // Slightly lighter
        "#9cc2fa", // Even lighter
        "#a5c9fb", // Very light
        "#aecbfa", // Lightest
      ],
    },
  };

  const theme = isDarkMode ? colors.dark : colors.light;

  return (
    <Svg width={width} height={height} viewBox="0 0 800 200">
      <Rect
        width="800"
        height="200"
        fill={theme.background}
        rx="10" // Rounded corners
        ry="10"
      />
      <SvgText
        x="20"
        y="100"
        fontFamily="Arial, sans-serif"
        fontWeight="bold"
        fontSize="90"
        fill={theme.primary}
      >
        SBG
      </SvgText>
      <SvgText
        x="20"
        y="150"
        fontFamily="Arial, sans-serif"
        fontSize="24"
        fill={theme.secondary}
      >
        Scott Business Group
      </SvgText>
      {theme.squares.map((color, index) => (
        <Rect
          key={index}
          x={230 + index * 60}
          y="40"
          width="50"
          height="50"
          fill={color}
          rx="5" // Rounded corners for squares
          ry="5"
        />
      ))}
    </Svg>
  );
};

export default Logo;
