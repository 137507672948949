// Import statements
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  useColorScheme as useDeviceColorScheme,
  TextInput,
  Pressable,
  FlatList,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import axios from "axios";
import moment from "moment-timezone";
import { FIREBASE_AUTH } from "../../FirebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import CustomLoading from "./CustomLoading";
import jsPDF from "jspdf";
import RNHTMLtoPDF from "react-native-html-to-pdf";

const BackButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={styles.backButton}
      onPress={() => navigation.goBack()}
    >
      <Feather name="arrow-left" size={24} color="#007AFF" />
    </TouchableOpacity>
  );
};

const StatsSection = ({ colorScheme, stats }) => (
  <View style={styles.statsContainer}>
    <View
      style={[styles.statsCard, colorScheme === "dark" && styles.statsCardDark]}
    >
      <MaterialCommunityIcons
        name="calendar-check"
        size={24}
        color={colorScheme === "dark" ? "#34C759" : "#2ECC71"}
      />
      <Text
        style={[
          styles.statsValue,
          colorScheme === "dark" && styles.statsValueDark,
        ]}
      >
        {stats.approved}
      </Text>
      <Text
        style={[
          styles.statsLabel,
          colorScheme === "dark" && styles.statsLabelDark,
        ]}
      >
        Approved
      </Text>
    </View>

    <View
      style={[styles.statsCard, colorScheme === "dark" && styles.statsCardDark]}
    >
      <MaterialCommunityIcons
        name="calendar-clock"
        size={24}
        color={colorScheme === "dark" ? "#007AFF" : "#3498DB"}
      />
      <Text
        style={[
          styles.statsValue,
          colorScheme === "dark" && styles.statsValueDark,
        ]}
      >
        {stats.pending}
      </Text>
      <Text
        style={[
          styles.statsLabel,
          colorScheme === "dark" && styles.statsLabelDark,
        ]}
      >
        Pending
      </Text>
    </View>

    <View
      style={[styles.statsCard, colorScheme === "dark" && styles.statsCardDark]}
    >
      <MaterialCommunityIcons
        name="calendar-remove"
        size={24}
        color={colorScheme === "dark" ? "#FF3B30" : "#FF3B30"}
      />
      <Text
        style={[
          styles.statsValue,
          colorScheme === "dark" && styles.statsValueDark,
        ]}
      >
        {stats.rejected}
      </Text>
      <Text
        style={[
          styles.statsLabel,
          colorScheme === "dark" && styles.statsLabelDark,
        ]}
      >
        Rejected
      </Text>
    </View>
  </View>
);

const LeaveReport = () => {
  // State variables
  const navigation = useNavigation();
  const deviceColorScheme = useDeviceColorScheme();
  const [colorScheme, setColorScheme] = useState(deviceColorScheme);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [userData, setUserData] = useState(null);
  const [stats, setStats] = useState({
    approved: 0,
    pending: 0,
    rejected: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Functions
  const fetchUserData = useCallback(async () => {
    const user = FIREBASE_AUTH.currentUser;
    if (user) {
      try {
        const db = getFirestore();
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData({ ...docSnap.data(), uid: user.uid });
        } else {
          console.log("No user data found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
      }
    }
  }, []);

  const fetchLeaveRequests = useCallback(async () => {
    if (!userData) return;

    setLoading(true);
    setError(null);
    try {
      const startDate = currentMonth
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = currentMonth.clone().endOf("month").format("YYYY-MM-DD");

      let allEmployeesResponse;
      if (userData.role === "admin") {
        allEmployeesResponse = await axios.get(
          "https://sbg-backend.onrender.com/employees"
        );
      } else if (userData.role === "supervisor") {
        allEmployeesResponse = await axios.get(
          "https://sbg-backend.onrender.com/employees",
          { params: { supervisorName: userData.name } }
        );
      } else {
        allEmployeesResponse = {
          data: [
            {
              employeeId: userData.employeeId,
              employeeName: userData.name,
            },
          ],
        };
      }

      const leaveRequests = await Promise.all(
        allEmployeesResponse.data.map(async (employee) => {
          const leaveResponse = await axios.get(
            `https://sbg-backend.onrender.com/personal-leave/${employee.employeeId}`,
            { params: { startDate, endDate } }
          );
          return leaveResponse.data.map((request) => ({
            ...request,
            employeeName: employee.employeeName,
            employeeId: employee.employeeId,
            dateFrom: new Date(request.dateFrom),
            dateTo: new Date(request.dateTo),
            approvedDate: request.approvedDate
              ? new Date(request.approvedDate)
              : null,
          }));
        })
      );

      const flattenedLeaveRequests = leaveRequests.flat();
      setLeaveRequests(flattenedLeaveRequests);
      setFilteredLeaveRequests(flattenedLeaveRequests);
    } catch (err) {
      console.error("Error fetching leave requests:", err);
      setError(
        err.message || "Failed to fetch leave requests. Please try again."
      );
    } finally {
      setLoading(false);
    }
  }, [userData, currentMonth]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (userData) {
      fetchLeaveRequests();
    }
  }, [fetchLeaveRequests, userData, currentMonth]);

  useEffect(() => {
    const filtered = leaveRequests.filter((request) =>
      request.employeeName.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredLeaveRequests(filtered);
  }, [searchInput, leaveRequests]);

  useEffect(() => {
    const approved = leaveRequests.filter(
      (req) => req.status === "Approved"
    ).length;
    const pending = leaveRequests.filter(
      (req) => req.status === "Pending"
    ).length;
    const rejected = leaveRequests.filter(
      (req) => req.status === "Rejected"
    ).length;

    setStats({
      approved,
      pending,
      rejected,
    });
  }, [leaveRequests]);

  const toggleColorScheme = () => {
    setColorScheme((prevScheme) => (prevScheme === "light" ? "dark" : "light"));
  };

  const dynamicStyles = useMemo(
    () => createDynamicStyles(colorScheme),
    [colorScheme]
  );

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredLeaveRequests.slice(startIndex, endIndex);
  }, [filteredLeaveRequests, currentPage, itemsPerPage]);

  const PaginationControls = () => {
    const totalPages = Math.ceil(filteredLeaveRequests.length / itemsPerPage);

    if (totalPages <= 1) return null;

    return (
      <View
        style={[
          styles.paginationContainer,
          colorScheme === "dark" && styles.paginationContainerDark,
        ]}
      >
        <TouchableOpacity
          onPress={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          style={[
            styles.paginationButton,
            currentPage === 1 && styles.paginationButtonDisabled,
          ]}
        >
          <Feather
            name="chevron-left"
            size={24}
            color={currentPage === 1 ? "#8E8E93" : "#007AFF"}
          />
        </TouchableOpacity>

        <Text
          style={[
            styles.paginationText,
            colorScheme === "dark" && styles.paginationTextDark,
          ]}
        >
          Page {currentPage} of {totalPages}
        </Text>

        <TouchableOpacity
          onPress={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          style={[
            styles.paginationButton,
            currentPage === totalPages && styles.paginationButtonDisabled,
          ]}
        >
          <Feather
            name="chevron-right"
            size={24}
            color={currentPage === totalPages ? "#8E8E93" : "#007AFF"}
          />
        </TouchableOpacity>
      </View>
    );
  };

  const renderLeaveRequest = ({ item: request }) => (
    <Pressable
      onPress={() =>
        navigation.navigate("Leave Request", {
          employeeId: request.employeeId,
          requestId: request._id,
        })
      }
      style={({ pressed }) => [
        styles.requestItem,
        colorScheme === "dark" && styles.requestItemDark,
        {
          backgroundColor: pressed
            ? colorScheme === "dark"
              ? "#333"
              : "#F0F0F0"
            : colorScheme === "dark"
            ? "#1E1E1E"
            : "white",
        },
      ]}
    >
      <View style={styles.requestInfo}>
        {/* Employee Name */}
        <Text
          style={[
            styles.employeeName,
            colorScheme === "dark" && styles.employeeNameDark,
          ]}
        >
          {request.employeeName}
        </Text>
        {/* Date Range */}
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          {moment(request.dateFrom).format("MMM D")} -{" "}
          {moment(request.dateTo).format("MMM D, YYYY")}
        </Text>
        {/* Additional Fields */}
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Client: {request.clientName}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Leave Type: {request.leaveType}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Total Days Off: {request.totalDaysOff}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Reason: {request.reasonForAbsence}
        </Text>
        <Text
          style={[
            styles.requestDetail,
            colorScheme === "dark" && styles.requestDetailDark,
          ]}
        >
          Status: {request.status}
        </Text>
        {request.approvedBy && (
          <Text
            style={[
              styles.requestDetail,
              colorScheme === "dark" && styles.requestDetailDark,
            ]}
          >
            Approved By: {request.approvedBy}
          </Text>
        )}
        {request.approvedDate && (
          <Text
            style={[
              styles.requestDetail,
              colorScheme === "dark" && styles.requestDetailDark,
            ]}
          >
            Approved Date: {moment(request.approvedDate).format("MMM D, YYYY")}
          </Text>
        )}
      </View>
      <TouchableOpacity
        style={styles.pdfButton}
        onPress={() => generatePDF(request)}
      >
        <Feather name="file-text" size={24} color="#007AFF" />
      </TouchableOpacity>
      <Feather name="chevron-right" size={24} color="#C7C7CC" />
    </Pressable>
  );

  const generatePDF = async (request) => {
    if (Platform.OS === "web") {
      // Web PDF generation using jsPDF
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const margin = 20;
      let yPosition = 20;

      // Helper function to add wrapped text and return new Y position
      const addWrappedText = (label, value) => {
        doc.setFontSize(12);
        doc.text(`${label}:`, margin, yPosition);

        // Calculate available width for the value
        const labelWidth = doc.getTextWidth(`${label}: `);
        const maxWidth = pageWidth - margin * 2 - labelWidth;

        // Split long text into lines
        const lines = doc.splitTextToSize(value, maxWidth);

        // Print each line
        lines.forEach((line, index) => {
          if (index === 0) {
            // First line goes next to label
            doc.text(line, margin + labelWidth, yPosition);
          } else {
            // Subsequent lines are indented
            yPosition += 7;
            doc.text(line, margin + labelWidth, yPosition);
          }
        });

        yPosition += 12; // Add space after the field
        return yPosition;
      };

      // Header
      doc.setFontSize(18);
      doc.text("Leave Request Details", pageWidth / 2, yPosition, {
        align: "center",
      });
      yPosition += 20;

      // Company details
      doc.setFontSize(10);
      doc.text("Scott Business Group", margin, yPosition);
      yPosition += 5;
      doc.text(
        "4600 East Washington Street, Suite 300, Phoenix, Arizona 85034",
        margin,
        yPosition
      );
      yPosition += 5;
      doc.text(
        "T: 602.604.9928 | F: 623.321.1327 | E: timesheets@scottbiz.net",
        margin,
        yPosition
      );
      yPosition += 15;

      // Content with wrapped text
      doc.setFontSize(12);
      yPosition = addWrappedText("Employee Name", request.employeeName);
      yPosition = addWrappedText("Employee ID", request.employeeId);
      yPosition = addWrappedText("Client", request.clientName);
      yPosition = addWrappedText("Leave Type", request.leaveType);
      yPosition = addWrappedText(
        "Date From",
        moment(request.dateFrom).format("MMM D, YYYY")
      );
      yPosition = addWrappedText(
        "Date To",
        moment(request.dateTo).format("MMM D, YYYY")
      );
      yPosition = addWrappedText(
        "Total Days Off",
        request.totalDaysOff.toString()
      );
      yPosition = addWrappedText("Reason", request.reasonForAbsence);
      yPosition = addWrappedText("Status", request.status);

      if (request.approvedBy) {
        yPosition = addWrappedText("Approved By", request.approvedBy);
      }
      if (request.approvedDate) {
        yPosition = addWrappedText(
          "Approved Date",
          moment(request.approvedDate).format("MMM D, YYYY")
        );
      }

      // Add company policy text
      yPosition += 10;
      doc.setFontSize(10);
      const policyText = `Under the ARIZONA FAIR WAGES AND HEALTHY FAMILIES ACT, Earned Paid Sick Time may be used for medical care, public health emergencies, or absence due to domestic violence. Documentation is required for three or more consecutive days of absence.`;

      const splitText = doc.splitTextToSize(policyText, pageWidth - margin * 2);
      doc.text(splitText, margin, yPosition);

      // Save the PDF
      const fileName = `Leave_Request_${request.employeeId}_${moment(
        request.dateFrom
      ).format("YYYYMMDD")}.pdf`;
      doc.save(fileName);
    } else {
      // React Native PDF generation
      try {
        const htmlContent = `
          <html>
            <head>
              <style>
                body { 
                  font-family: Arial, sans-serif; 
                  padding: 20px; 
                  line-height: 1.6;
                }
                h1 { 
                  text-align: center; 
                  color: #333; 
                  margin-bottom: 20px;
                }
                .company-info {
                  text-align: left;
                  font-size: 0.9em;
                  margin-bottom: 20px;
                }
                .field { 
                  margin: 10px 0;
                  word-wrap: break-word;
                }
                .label { 
                  font-weight: bold;
                  display: inline-block;
                  min-width: 120px;
                }
                .value {
                  display: inline-block;
                  max-width: calc(100% - 130px);
                  vertical-align: top;
                }
                .policy { 
                  margin-top: 30px;
                  font-size: 0.9em;
                  color: #666;
                  padding: 15px;
                  background-color: #f9f9f9;
                  border-radius: 5px;
                }
              </style>
            </head>
            <body>
              <h1>Leave Request Details</h1>
              <div class="company-info">
                Scott Business Group<br>
                4600 East Washington Street, Suite 300, Phoenix, Arizona 85034<br>
                T: 602.604.9928 | F: 623.321.1327 | E: timesheets@scottbiz.net
              </div>
              <div class="field"><span class="label">Employee Name:</span> <span class="value">${
                request.employeeName
              }</span></div>
              <div class="field"><span class="label">Employee ID:</span> <span class="value">${
                request.employeeId
              }</span></div>
              <div class="field"><span class="label">Client:</span> <span class="value">${
                request.clientName
              }</span></div>
              <div class="field"><span class="label">Leave Type:</span> <span class="value">${
                request.leaveType
              }</span></div>
              <div class="field"><span class="label">Date From:</span> <span class="value">${moment(
                request.dateFrom
              ).format("MMM D, YYYY")}</span></div>
              <div class="field"><span class="label">Date To:</span> <span class="value">${moment(
                request.dateTo
              ).format("MMM D, YYYY")}</span></div>
              <div class="field"><span class="label">Total Days Off:</span> <span class="value">${
                request.totalDaysOff
              }</span></div>
              <div class="field"><span class="label">Reason:</span> <span class="value">${
                request.reasonForAbsence
              }</span></div>
              <div class="field"><span class="label">Status:</span> <span class="value">${
                request.status
              }</span></div>
              ${
                request.approvedBy
                  ? `<div class="field"><span class="label">Approved By:</span> <span class="value">${request.approvedBy}</span></div>`
                  : ""
              }
              ${
                request.approvedDate
                  ? `<div class="field"><span class="label">Approved Date:</span> <span class="value">${moment(
                      request.approvedDate
                    ).format("MMM D, YYYY")}</span></div>`
                  : ""
              }
              <div class="policy">
                Under the ARIZONA FAIR WAGES AND HEALTHY FAMILIES ACT, Earned Paid Sick Time may be used for medical care, 
                public health emergencies, or absence due to domestic violence. Documentation is required for three or more 
                consecutive days of absence.
              </div>
            </body>
          </html>
        `;

        const options = {
          html: htmlContent,
          fileName: `Leave_Request_${request.employeeId}_${moment(
            request.dateFrom
          ).format("YYYYMMDD")}`,
          directory: "Documents",
        };

        const file = await RNHTMLtoPDF.convert(options);
        console.log("PDF generated:", file.filePath);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };

  if (loading) {
    return (
      <SafeAreaView style={[styles.container, dynamicStyles.container]}>
        <View style={dynamicStyles.loadingContainer}>
          <CustomLoading
            isDarkMode={colorScheme === "dark"}
            width={300}
            height={75}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={[styles.container, dynamicStyles.container]}>
      <StatusBar
        barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        backgroundColor={colorScheme === "dark" ? "#000" : "#F0F0F0"}
      />
      <View style={styles.content}>
        {/* Header */}
        <View
          style={[styles.header, colorScheme === "dark" && styles.headerDark]}
        >
          <BackButton />
          <Text
            style={[styles.title, colorScheme === "dark" && styles.titleDark]}
          >
            Leave Report
          </Text>
          <TouchableOpacity
            onPress={toggleColorScheme}
            style={styles.themeToggle}
          >
            <Feather
              name={colorScheme === "dark" ? "sun" : "moon"}
              size={24}
              color="#007AFF"
            />
          </TouchableOpacity>
        </View>

        {/* Stats Section */}
        <StatsSection colorScheme={colorScheme} stats={stats} />

        {/* Search Input */}
        <View
          style={[
            styles.searchContainer,
            colorScheme === "dark" && styles.searchContainerDark,
          ]}
        >
          <Feather
            name="search"
            size={20}
            color={colorScheme === "dark" ? "#8E8E93" : "#8E8E93"}
            style={styles.searchIcon}
          />
          <TextInput
            style={[
              styles.searchInput,
              colorScheme === "dark" && styles.searchInputDark,
            ]}
            placeholder="Search by name"
            placeholderTextColor={
              colorScheme === "dark" ? "#8E8E93" : "#8E8E93"
            }
            value={searchInput}
            onChangeText={setSearchInput}
          />
        </View>

        {/* Leave Requests */}
        <FlatList
          data={paginatedData}
          renderItem={renderLeaveRequest}
          keyExtractor={(item) => item._id}
          ListEmptyComponent={
            <Text
              style={[
                styles.noRequestsText,
                colorScheme === "dark" && styles.noRequestsTextDark,
              ]}
            >
              No leave requests found for this period.
            </Text>
          }
          contentContainerStyle={styles.requestList}
          ListFooterComponent={<PaginationControls />}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  // Container styles
  container: {
    flex: 1,
  },
  // Header styles
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  headerDark: {
    backgroundColor: "#1E1E1E",
    borderBottomColor: "#333333",
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#000000",
  },
  titleDark: {
    color: "#FFFFFF",
  },
  // Search container styles
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    marginHorizontal: 16,
    marginVertical: 12,
    paddingHorizontal: 12,
    height: 44,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  searchContainerDark: {
    backgroundColor: "#2C2C2E",
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: "#000000",
  },
  searchInputDark: {
    color: "#FFFFFF",
  },
  // Request item styles
  requestItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    marginHorizontal: 16,
    marginVertical: 8,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  requestItemDark: {
    borderBottomColor: "#333333",
  },
  requestInfo: {
    flex: 1,
  },
  employeeName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000000",
  },
  employeeNameDark: {
    color: "#FFFFFF",
  },
  requestDetail: {
    fontSize: 16,
    color: "#000000",
  },
  requestDetailDark: {
    color: "#D1D1D6",
  },
  // No requests text
  noRequestsText: {
    fontSize: 16,
    textAlign: "center",
    marginTop: 20,
    color: "#000000",
  },
  noRequestsTextDark: {
    color: "#FFFFFF",
  },
  content: {
    flex: 1,
  },
  requestList: {
    flexGrow: 1,
  },
  pdfButton: {
    padding: 8,
    marginRight: 8,
  },
  statsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    gap: 12,
    paddingHorizontal: 16,
    marginTop: 16,
  },
  statsCard: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    padding: 16,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  statsCardDark: {
    backgroundColor: "#333",
    shadowColor: "#FFF",
  },
  statsValue: {
    fontSize: 24,
    fontWeight: "600",
    color: "#000000",
    marginVertical: 8,
  },
  statsValueDark: {
    color: "#FFFFFF",
  },
  statsLabel: {
    fontSize: 14,
    color: "#666666",
    textAlign: "center",
  },
  statsLabelDark: {
    color: "#8E8E93",
  },
  paginationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16,
    borderTopWidth: 1,
    borderTopColor: "#E0E0E0",
    backgroundColor: "#FFFFFF",
    marginTop: 8,
    marginHorizontal: 16,
    borderRadius: 12,
  },
  paginationContainerDark: {
    backgroundColor: "#1E1E1E",
    borderTopColor: "#333333",
  },
  paginationButton: {
    padding: 8,
    borderRadius: 8,
  },
  paginationButtonDisabled: {
    opacity: 0.5,
  },
  paginationText: {
    fontSize: 16,
    marginHorizontal: 16,
    color: "#000000",
  },
  paginationTextDark: {
    color: "#FFFFFF",
  },
});

const createDynamicStyles = (colorScheme) => ({
  container: {
    backgroundColor: colorScheme === "dark" ? "#121212" : "#f9f9f9",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === "dark" ? "#1a2e22" : "#e6f0e8",
  },
});

export default LeaveReport;
